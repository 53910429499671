import { PropsWithChildren, useRef, useState } from "react";

import naira from "@/assets/img/naira_currency.svg";
import useClickOutside from "@/hooks/useClickOutside";
import "@/styles/form.scss";

interface Props {
    input: {
        label?: string;
        placeholder: string;
        name: string;
    };
    className?: string;
    showCurrency?: boolean;
    value?: string | number;
    onSelect?: (value: string) => void;
    type?: string;
    maxLength?: number;
    required?: boolean;
    onFocus?: () => void;
    min?: number;
    options?: Array<{ label: string; value: string }>;
    error?: string;
    index?: number;
    testId?: string;
    use_label?: boolean
    children?: JSX.Element;
}
export default function InputDropdown({
    input,
    onSelect,
    className,
    error,
    showCurrency = false,
    value = "",
    type = "text",
    maxLength = undefined,
    required = false,
    index,
    options,
    testId,
    use_label = false,
    children,
    ...props
}: PropsWithChildren<Props>) {
    const inputClassname = className ? className : "";
    const htmlForText = index ? `${input.name}_${index}` : input.name;
    const testIdData = testId ? { "data-testid": testId } : {};
    const [dropdown, setDropdown] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, closeDropdown);

    function showDropdown() {
        setDropdown(true);
    }

    function closeDropdown() {
        setDropdown(false);
    }

    function onSelectValueHandler(value: string) {
        if (onSelect) onSelect(value);
        closeDropdown();
    }

    return (
        <div className={`form_element input input_dropdown  ${inputClassname}`}>
            <input
                {...props}
                {...testIdData}
                id={htmlForText}
                name={input.name}
                placeholder={input.placeholder}
                className="form-control"
                value={value}
                type={type}
                onClick={showDropdown}
                maxLength={maxLength}
                required={required}
                readOnly={true}
            />

            {dropdown && (
                <ul ref={ref}>
                    {children ?
                        children :
                        options && options.map((option, index) => {
                            const className = value === option.label ? "active" : "";
                            const optionValue = use_label ? option.label: option.value;
                            return (
                                <li key={index} className={className} onClick={() => onSelectValueHandler(optionValue)}>
                                    {option.label}
                                </li>
                            );
                        })}
                </ul>
            )}
            {input?.label && <label htmlFor={htmlForText}>{input?.label}</label>}
            {showCurrency && <img src={naira} alt="Naira" className="icon" />}
            {error && <p className="text-danger small">{error}</p>}
        </div>
    );
}
