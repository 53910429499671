import { getAuth } from "firebase/auth";
import { Suspense, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import type { PropsWithChildren } from "react";

import { getUserDetails } from "@/axios/get-request";
import Footer from "@/components/Footer";
import DashboardSidebar from "@/components/DashboardSidebar";
import logo from "@/assets/img/logo.png";
import ChangePhotoModal from "@/components/modals/ChangePhotoModal";
import getFirstChar from "@/utils/getFirstChar";
import VendorAssistanceModal from "@/components/modals/VendorAssistanceModal";
import Hamburger from "@/assets/icon/Hamburger";
import CancelIcon from "@/assets/icon/Cancel";
import { useEvent } from "@/hooks/useEvent";
import { useFilter } from "@/hooks/useFilter";
import useMyEvents from "@/hooks/useMyEvents";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus } from "@/types";
import { HotjarIdentifyUser } from "@/InitializeHotjar";
import "@/styles/dashboard.scss";
import InfoIcon from "@/assets/icon/InfoIcon";

interface Props {
	showIcon?: boolean;
	showSidebar?: boolean;
	className?: string;
	title: string;
}

function DashboardLayout({ title, children, showIcon = false, showSidebar = true, className = "" }: PropsWithChildren<Props>) {
    const [showChangePhotoModal, setShowChangePhotoModal] = useState(false);
    const user = getAuth().currentUser;
    const [modal, setModal] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const location = useLocation();
    const { selectedEventId, setSelectedEventId } = useEvent();
    const { setCeremony } = useFilter();
    const { activeEvent } = useMyEvents();
    const navigate = useNavigate();

    const selectedEvent = activeEvent ? activeEvent[0] : null;
    const currentCollaborator = selectedEvent ? selectedEvent?.collaborators.filter((collaborator) => collaborator.id === user?.uid)[0] : null;

    const { status, data } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => {
            return getUserDetails();
        },
    });

    const _displayName = data?.result?.name || user?.displayName;
    const displayName = _displayName as string;
    const email = data?.result?.email as string;

    // start tracking with FullStory on production
    // useEffect(() => {
    //     if (user && process.env.NODE_ENV !== "development") {
    //         FullStory.identify(user.uid, {
    //             displayName,
    //             email,
    //         });
    //     }
    // }, []);

    // display vendor assistance modal in a dashboard route only
    useEffect(() => {
        const allTourGuidesDone = data?.result?.tour_guide && !Object.values(data?.result.tour_guide).includes(false);
        if (
            !data?.result?.action?.close_one_time_vendor_assistance_modal &&
			location.pathname !== "/dashboard/events" &&
			location.pathname.includes("/dashboard") &&
			status === "success" &&
			allTourGuidesDone
        ) {
            const timer = setTimeout(() => {
                setModal(true);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [status]);

    // show renew access modal if current collaborator is owing
    useEffect(() => {
        if (location.pathname !== "/dashboard/events") {
            if (
                currentCollaborator &&
				currentCollaborator.payment_plan === CollaboratorPaymentPlan.PAID &&
				currentCollaborator.plan_status === CollaboratorPlanStatus.EXPIRED
            ) {
                navigate("/dashboard/events");
            }
        }
    }, [currentCollaborator?.role]);

    async function closeModalHandler() {
        setModal(false);
    }

    function toggleMobileMenu() {
        setMobileMenu((prev) => !prev);
    }

    const nameFirstChar = displayName ? getFirstChar(displayName) : getFirstChar(email);

    function showChangePhotoModalHandler() {
        setShowChangePhotoModal(true);
    }

    function closeChangePhotoModalHandler() {
        setShowChangePhotoModal(false);
    }

    const userProfilePicture = activeEvent && activeEvent[0]?.event_image ? activeEvent[0]?.event_image : null;

    const mobileAsideClassName = mobileMenu ? "open_menu" : "close_menu";

    const dashboardClassName = !showSidebar ? "no_sidebar" : "";

    function selectDefaultEvent() {
        if (!selectedEventId && activeEvent) {
            setSelectedEventId(activeEvent[0].id);
            setCeremony({
                name: "All Ceremonies",
                id: "all",
            });
        }
    }

    return (
        <>
            {status === "success" && data?.result?.email && <HotjarIdentifyUser userEmail={data.result.email} />}
            <Helmet>
                <title>{title} Planaday | Effortless Event Planning & Management</title>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta
                    name="keywords"
                    content="nigerian wedding, asoebi, ankara, lagos wedding, wedding budget, wedding cost, Planaday, plan an event, wedding planning,
                    event planning, event budgeting, event manager, event planner, wedding planner, planning assistant"
                />
                <meta property="og:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    property="og:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta property="og:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta property="og:url" content="https://www.planaday.events" />
                <meta property="og:site_name" content="Planaday Events" />
                <meta property="og:type" content="website" />
                <meta property="og:type" content="Planaday Events" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    name="twitter:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta name="twitter:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:image:alt" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:site" content="@Planaday_events" />
                <meta name="twitter:creator" content="@Planaday_events" />
            </Helmet>
            <ToastContainer autoClose={2000} newestOnTop={false} closeOnClick rtl={false} />
            <Suspense fallback={<div>Loading...</div>}>
                {modal && !data?.result?.action?.close_one_time_vendor_assistance_modal && (
                    <VendorAssistanceModal show={modal} onHide={closeModalHandler} showOnceOnHomepage={true} />
                )}
                {showChangePhotoModal && showIcon && (
                    <ChangePhotoModal
                        show={showChangePhotoModal}
                        eventBg={selectedEvent?.event_bg}
                        imageURL={userProfilePicture}
                        onHide={closeChangePhotoModalHandler}
                    />
                )}
            </Suspense>
            <div className="new_ui_banner">
                <InfoIcon /> <b>Our new UI is in beta! Whilst we perfect things</b>,{" "}
                <span>we will be allowing you to access both interfaces so you can test things out. </span>
                <Link to="/new/dashboard">Try New UI</Link>
            </div>
            <header className="dashboard_header bg-white d-flex flex-column">
                <div className="header_content d-flex align-items-center justify-content-between">
                    <Link to="/dashboard" onClick={selectDefaultEvent}>
                        <img src={logo} alt="Planaday logo" className="logo-img" />
                    </Link>
                    <button className="d-sm-none d-flex mobile_menu" onClick={toggleMobileMenu}>
                        {!mobileMenu ? <Hamburger /> : <CancelIcon />}
                    </button>
                    <nav className="d-flex align-items-center">
                        {/* <Link to="/dashboard/budget-tracker">Planning Tools</Link> */}
                        <a href="https://www.blog.planaday.events/" target="_blank">
							Community{" "}
                        </a>
                        <a href="https://planaday.events/about.html" target="_blank">
							About
                        </a>
                        <div className="d-flex nav_profile_icon_group">
                            {nameFirstChar && (
                                <Link to="/dashboard/edit-profile">
                                    <div className="profile">{nameFirstChar}</div>
                                </Link>
                            )}
                            <button className="mobile_menu tablet_view" onClick={toggleMobileMenu}>
                                {!mobileMenu ? <Hamburger /> : <CancelIcon />}
                            </button>
                        </div>
                    </nav>
                </div>
                <div className="section-bar" />
            </header>
            <main className={`dashboard ${dashboardClassName} ${className} ${mobileAsideClassName}`}>
                <div className="d-flex dashboard_layout_wrapper">
                    {showSidebar && (
                        <DashboardSidebar>
                            <>
                                {showIcon && (
                                    <div className="profile-icon">
                                        {selectedEvent?.event_image && userProfilePicture ? (
                                            <img src={userProfilePicture} alt="Profile Icon" />
                                        ) : (
                                            <div className="profile_banner" style={{ backgroundColor: selectedEvent?.event_bg }} />
                                        )}
                                        <div>
                                            <button onClick={showChangePhotoModalHandler}>CHANGE PHOTO</button>
                                        </div>
                                    </div>
                                )}
                            </>
                        </DashboardSidebar>
                    )}
                    <div className="dashboard_content">{children}</div>
                </div>
                <div className="section-bar" />
            </main>
            <Footer />
        </>
    );
}

export default DashboardLayout;
