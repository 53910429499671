import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CalculatorAnswer from "@/components/CalculatorAnswer";
import CalculatorForm from "@/components/CalculatorForm";
import { run_model } from "@/axios/post-request";
import type { InputData, ModelResult, budgetForm } from "@/data/types";

type formType = {
	form: budgetForm;
	data: InputData[];
};

export default function CalculatorView() {
    const [view, setView] = useState("form");
    const containerRef = useRef<HTMLDivElement>(null);
    const [form, setForm] = useState<formType>();
    const [modelResult, setModelResult] = useState<ModelResult>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function onClickCalculatorHandler(form: budgetForm, data: InputData[]) {
        try {
            setForm({ form, data });
            setLoading(true);
            const answer = await run_model(data);
            setLoading(false);
            if (answer) {
                setModelResult(answer);
                setView("answer");
                navigate("/?page=view-event-budget");
            }
        } catch (error: unknown) {
            const _error = error as { message: string };
            setLoading(false);
            toast.error(_error.message || "An error occurred, try again later!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    return (
        <div className="container calculator_view" ref={containerRef}>
            <>
                {view === "form" ? (
                    <CalculatorForm
                        saved={form}
                        onClick={(form: budgetForm, data: InputData[]) => onClickCalculatorHandler(form, data)}
                        isLoading={loading}
                    />
                ) : modelResult && form ? (
                    <CalculatorAnswer modelResult={modelResult} form={form.form} data={form.data} onClick={() => setView("form")} />
                ) : (
                    <> </>
                )}
            </>
        </div>
    );
}
