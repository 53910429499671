import { Stage, Layer, Rect, Transformer } from "react-konva";
// import { jsPDF } from "jspdf";
import { v4 as uuidv4 } from "uuid";
// import { HexColorPicker } from "react-colorful";
// import { DriveStep, driver as Driver } from "driver.js";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Konva from "konva";
// import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { useState, useRef, useEffect, ChangeEvent } from "react";
// import "driver.js/dist/driver.css";
import useToast from "@/hooks/useToast";

import useFloorPlan from "@/hooks/useFloorPlan";
import DisplayFloorPlanElement from "@/components/DisplayFloorPlanElement";
import { FloorPlanObjectList, FloorPlanObjectListType, type FloorplanObjectType } from "@/data/types";
import SiteDropdown from "../Dropdown";
import UndoIcon from "@/assets/icon/UndoIcon";
import RedoIcon from "@/assets/icon/RedoIcon";
import SquareDashedIcon from "@/assets/icon/SquareDashedIcon";
// import DownloadIcon from "@/assets/icon/DownloadIcon";
import Search from "@/assets/icon/Search";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import { fillSpaceElements, fillSpaceObjectElements } from "@/utils/draw_floor_plan_icons";
import TextIcon from "@/assets/icon/TextIcon";
import ShrinkIcon from "@/assets/icon/ShrinkIcon";
import ExpandIcon from "@/assets/icon/ExpandIcon";
// import SaveIcon from "@/assets/icon/SaveIcon";
import LinesIcon from "@/assets/icon/LinesIcon";
import DimensionElement from "@/assets/icon/FloorPlanIcon/DimensionElement";
import UploadImageIcon from "@/assets/icon/UploadImageIcon";
import { addFloorplanElementRequest, createFloorPlanRequest, uploadFloorPlanElementRequest } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";
import PlusIcon from "@/assets/icon/PlusIcon";
import { deleteFloorplanRequest } from "@/axios/delete-request";

// type pdfType = { addImage: (uri: string, format: "PNG", x: number, y: number) => void; save: (pdfName: string) => void };

const canvasBoardStyle = { width: "100%", minWidth: "100%", height: "100%" };
const stageStyle = {
    backgroundColor: "#f9fafc",
    border: "1px solid #5455660D",
};

interface Props {
    fullViewHandler: (value: boolean) => void;
}

export default function FloorplanDrawCanvas({ fullViewHandler }: Props) {
    const {
        floorPlanStage,
        transformFloorPlanElement,
        activeFloorPlanStageId,
        activeFloorPlanElementId,
        selectActiveFloorPlanElementId,
        toggleSidebar,
        sidebar,
        updateFloorPlanTextHandler,
        updateActiveFloorPlanStageId,
        addFloorPlanStageObjectHandler,
        deleteFloorPlanHandler,
        addFloorPlanTextHandler,
        selectActiveObjectHandler,
        updateFloorPlanName,
        addDefaultFloorPlanHandler,
        updateFloorPlanElementHandler,
    } = useFloorPlan();
    const currentFloorPlan = floorPlanStage.filter((item) => item.id === activeFloorPlanStageId);
    const currentFloorPlanIndex = floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId);
    const [pickElementStage, setPickElementStage] = useState(fillSpaceElements[0].type);
    const { selectedEventId, activeCeremonyId } = useEvent();
    const [stageScale, setStageScale] = useState({
        scale: 1,
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    });
    const canvasBoardRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const stageRef: React.MutableRefObject<null | any> = useRef(null);
    const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
    const currentFloorPlanDetail = activeFloorPlanStageId ? floorPlanStage.filter((item) => item.id === activeFloorPlanStageId)[0] : null;
    const [history, setHistory] = useState([currentFloorPlanDetail?.stage]);
    const [selectedIds, selectShapes] = useState<string[]>([]);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const layerRef: React.MutableRefObject<null | any> = useRef();
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const trRef: React.MutableRefObject<null | any> = useRef();
    const oldPos = useRef(null);
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("loading_floorplan_id");
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const selectionRectRef: React.MutableRefObject<null | any> = useRef();
    const selection = useRef({
        visible: false,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
    });

    const currentFloorPlanStageIndex = floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId);

    const activeFloorPlanStageIndex = activeFloorPlanStageId ? floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId) : null;

    useEffect(() => {
        const nodes = selectedIds.map((id) => layerRef.current.findOne("#" + id));
        trRef.current?.nodes(nodes);
    }, [selectedIds]);

    useEffect(() => {
        if (!activeFloorPlanStageId && floorPlanStage.length > 0) {
            updateActiveFloorPlanStageId(floorPlanStage[0].id);
        }
    }, []);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [toggleSidebar]);

    function updateHistory(stageObject: Array<FloorplanObjectType>) {
        if (history && stageObject) {
            const newHistory = history.slice(0, currentHistoryIndex + 1);
            newHistory.push(stageObject);
            setHistory(newHistory);
            setCurrentHistoryIndex(newHistory.length - 1);
        }
    }

    const __stageScalePercentage = stageScale.scale * 100;
    const stageScalePercentage = __stageScalePercentage.toFixed(0);

    const handleChange = (stageObj: Array<FloorplanObjectType>) => {
        updateHistory(stageObj);
    };

    // function exportFloorPlanAsPDF() {
    //     if (currentFloorPlanDetail && currentFloorPlanDetail.stage.length === 0) {
    //         return toast.error("Floor plan is empty");
    //     }
    //     const uri = stageRef?.current?.toDataURL();
    //     const pdfInstance = new jsPDF();
    //     const pdf = pdfInstance as unknown as pdfType;
    //     pdf.addImage(uri, "PNG", 0, 0);
    //     pdf.save(`${currentFloorPlanDetail?.floor_plan_name}-floor-plan.pdf`);
    // }

    // function exportFloorPlanAsPNG() {
    //     if (currentFloorPlanDetail && currentFloorPlanDetail.stage.length === 0) {
    //         return toast.error("Floor plan is empty");
    //     }
    //     const uri = stageRef?.current?.toDataURL();
    //     const link = document.createElement("a");
    //     link.download = `${currentFloorPlanDetail?.floor_plan_name}-floor-plan.png`;
    //     link.href = uri;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }

    const updateSelectionRect = () => {
        const node = selectionRectRef.current;
        node.setAttrs({
            visible: selection.current.visible,
            x: Math.min(selection.current.x1, selection.current.x2),
            y: Math.min(selection.current.y1, selection.current.y2),
            width: Math.abs(selection.current.x1 - selection.current.x2),
            height: Math.abs(selection.current.y1 - selection.current.y2),
            fill: "rgba(0, 161, 255, 0.3)",
        });
        node.getLayer().batchDraw();
    };

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const onClickTap = (e: any) => {
        // if we are selecting with rect, do nothing
        const { x1, x2, y1, y2 } = selection.current;
        const moved = x1 !== x2 || y1 !== y2;
        if (moved) {
            return;
        }

        const stage = e.target.getStage();
        const layer = layerRef.current;
        const tr = trRef.current;
        // if click on empty area - remove all selections
        if (e.target === stage) {
            selectShapes([]);
            return;
        }

        // do we pressed shift or ctrl?
        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = tr.nodes().indexOf(e.target) >= 0;

        if (!metaPressed && !isSelected) {
            // if no key pressed and the node is not selected
            // select just one
            selectShapes([e.target.id()]);
        } else if (metaPressed && isSelected) {
            // if we pressed keys and node was selected
            // we need to remove it from selection:
            selectShapes((oldShapes) => {
                return oldShapes.filter((oldId) => oldId !== e.target.id());
            });
        } else if (metaPressed && !isSelected) {
            // add the node into selection
            selectShapes((oldShapes) => {
                return [...oldShapes, e.target.id()];
            });
        }
        layer.draw();
    };

    async function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        if (activeFloorPlanStageIndex !== null) {
            const floorPlanElementId = uuidv4();
            addFloorPlanStageObjectHandler(activeFloorPlanStageIndex, FloorPlanObjectList.image_element, floorPlanElementId, "", url);
            const formData = new FormData();
            formData.append("file", file);
            const uploadImageResult = await uploadFloorPlanElementRequest(formData, currentFloorPlan[0].id, floorPlanElementId);
            updateFloorPlanElementHandler(activeFloorPlanStageIndex, floorPlanElementId, uploadImageResult.data.result.url);
        }
    }

    function undoHandler() {
        if (currentHistoryIndex > 0 && history[currentHistoryIndex - 1]) {
            setCurrentHistoryIndex(currentHistoryIndex - 1);
            const prevHistory = history[currentHistoryIndex - 1] as Array<FloorplanObjectType>;
            transformFloorPlanElement(prevHistory, currentFloorPlanStageIndex);
        }
    }

    function redoHandler() {
        if (history && currentHistoryIndex < history.length - 1) {
            setCurrentHistoryIndex(currentHistoryIndex + 1);
            const nextHistory = history[currentHistoryIndex + 1] as Array<FloorplanObjectType>;
            transformFloorPlanElement(nextHistory, currentFloorPlanStageIndex);
        }
    }

    const updateDimensions = () => {
        if (canvasBoardRef.current) {
            const { offsetWidth, offsetHeight } = canvasBoardRef.current;
            setDimensions({ width: offsetWidth, height: offsetHeight });
        }
    };

    async function onDeleteFloorPlanHandler() {
        try {
            if (activeFloorPlanStageId) {
                loadingToast(toastId);
                await deleteFloorplanRequest(activeFloorPlanStageId, selectedEventId);
                deleteFloorPlanHandler(activeFloorPlanStageId);
                updateToast(toastId, "success", "Floor plan deleted");
            }
        } catch (error) {
            console.log("error", error);
            updateToast(toastId, "success", "Error deleting floor plan");
        }
    }

    function checkDeselect(e: { target?: { getStage: () => void } }) {
        // deselect when clicked on empty areaclear

        const clickedOnEmpty = e.target === e?.target?.getStage();
        if (clickedOnEmpty) {
            selectActiveFloorPlanElementId(null);
            selectActiveObjectHandler(currentFloorPlanIndex, null);
            // selectShapes([]);
        }
    }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function onWheelHandler(e: any) {
        e.evt.preventDefault();
        const scaleBy = 1.01;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        };

        const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

        setStageScale({
            scale: newScale,
            x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
            y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
        });
    }

    function resetWheelHandler() {
        setStageScale({
            scale: 1,
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        });
    }

    function onSelectObjectHandler(stageDetails: FloorplanObjectType) {
        selectActiveFloorPlanElementId(stageDetails.id);
        selectActiveObjectHandler(currentFloorPlanIndex, stageDetails.type);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function handleDragMove(e: any) {
        setStageScale({
            ...stageScale,
            x: e.target.x(),
            y: e.target.y(),
        });
    }

    function handleMouseUp() {
        oldPos.current = null;
        selection.current.visible = false;
        const { x1, x2, y1, y2 } = selection.current;
        const moved = x1 !== x2 || y1 !== y2;
        if (!moved) {
            updateSelectionRect();
            return;
        }
        const selBox = selectionRectRef.current.getClientRect();

        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const elements: any[] = [];
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        layerRef.current.find(".floor_plan_element").forEach((elementNode: any) => {
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                elements.push(elementNode);
            }
        });

        selectShapes(elements.map((el) => el.id()));
        updateSelectionRect();
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function handleMouseMove(event: any) {
        if (!selection.current.visible) {
            return;
        }
        const pos = event.target.getStage().getPointerPosition();
        selection.current.x2 = pos.x;
        selection.current.y2 = pos.y;
        updateSelectionRect();
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const handleMouseDown = (event: any) => {
        checkDeselect(event);
        const isElement = event.target.findAncestor(".elements-container");
        const isTransformer = event.target.findAncestor("Transformer");
        if (isElement || isTransformer) {
            return;
        }

        const pos = event.target.getStage().getPointerPosition();
        selection.current.visible = true;
        selection.current.x1 = pos.x;
        selection.current.y1 = pos.y;
        selection.current.x2 = pos.x;
        selection.current.y2 = pos.y;
        updateSelectionRect();
    };

    // const isDraggable = currentFloorPlan[0]?.active_object_type !== FloorPlanObjectList.regular__dimensions ? true : false;

    const pickElementStageValue = pickElementStage as
        | "table_chairs__category"
        | "entertainment__category"
        | "food_drinks__category"
        | "stage__category"
        | "doors__category"
        | "gifts__category"
        | "shapes__category";

    const floorPlanElements = fillSpaceObjectElements[pickElementStageValue] as Array<{
        title?: string;
        type: FloorPlanObjectListType;
        icon: JSX.Element;
    }>;
    const floorPlanStageCount = floorPlanStage.length > 0 ? floorPlanStage.length + 1 : "";
    const newFloorplanName = `New Floor plan ${floorPlanStageCount}`;

    async function addFloorPlanElementHandler(elementType: FloorPlanObjectListType, title?: string) {
        if (activeFloorPlanStageIndex !== null) {
            const floorPlanElementId = uuidv4();
            addFloorPlanStageObjectHandler(activeFloorPlanStageIndex, elementType, floorPlanElementId, title);
            const stageText = title ? { text: title } : {};
            const stage = floorPlanStage[activeFloorPlanStageIndex].stage.filter((item) => item.type === FloorPlanObjectList.regular__dimensions);
            const floorPlanObjectId = `${elementType}__${floorPlanElementId}`;

            await addFloorplanElementRequest(currentFloorPlan[0].id, floorPlanElementId, selectedEventId, {
                ...stageText,
                height: 50,
                width: 50,
                x: stage[0].x + stage[0].width / 2 + floorPlanStage[activeFloorPlanStageIndex].stage.length * 5,
                y: stage[0].y + stage[0].height / 2.5 + floorPlanStage[activeFloorPlanStageIndex].stage.length * 5,
                fill: "#D9D9D9",
                id: floorPlanObjectId,
                type: elementType,
            });
        }
    }

    function renameFloorPlanHandler(event: ChangeEvent<HTMLInputElement>) {
        if (activeFloorPlanStageIndex !== null) {
            updateFloorPlanName(event.target.value, activeFloorPlanStageIndex);
        }
    }

    function addFloorPlanHandler(name: string, id: string) {
        const stageWidth = window.innerWidth;
        const stageHeight = window.innerHeight;
        const dimensionElementSizes = {
            height: 400,
            width: 1000,
        };
        const divider = sidebar ? 10 : 5;
        const rectX = (stageWidth - dimensionElementSizes.width) / divider;
        const rectY = (stageHeight - dimensionElementSizes.height) / 10;
        const floorPlanObjectId = `${FloorPlanObjectList.regular__dimensions}__${id}`;

        return {
            id,
            floor_plan_name: name ? name : "New Floor Plan",
            floor_plan_icon: "",
            activeFloorPlanElementId: null,
            stage: [
                {
                    ...dimensionElementSizes,
                    fill: "white",
                    id: floorPlanObjectId,
                    x: rectX,
                    y: rectY,
                    type: FloorPlanObjectList.regular__dimensions,
                },
            ],
        };
    }

    async function addNewFloorPlanHandler() {
        try {
            const floorPlanId = uuidv4();
            addDefaultFloorPlanHandler(newFloorplanName, floorPlanId);
            const floorPlanDetails = addFloorPlanHandler(newFloorplanName, floorPlanId);
            await createFloorPlanRequest(selectedEventId, {
                ...floorPlanDetails,
                event_id: selectedEventId,
                ceremony_id: activeCeremonyId,
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    function addTextElementHandler() {
        if (activeFloorPlanStageIndex) {
            addFloorPlanTextHandler(activeFloorPlanStageIndex);
        }
    }

    const drawingBoardItem = currentFloorPlan
        ? currentFloorPlan[0]?.stage.filter((item) => item.type === FloorPlanObjectList.regular__dimensions)[0]
        : null;

    return (
        <section className="floor_plan">
            <div className="floor_plan_group">
                <div className="added_floor_plans">
                    {floorPlanStage.map((item, index) => {
                        const className = activeFloorPlanStageIndex === index ? "current" : "";
                        return (
                            <button key={index} onClick={() => updateActiveFloorPlanStageId(item.id)} className={className}>
                                <SquareDashedIcon /> <span> {item.floor_plan_name ? item.floor_plan_name : "New Floor Plan"} </span>
                            </button>
                        );
                    })}
                </div>
                <button onClick={addNewFloorPlanHandler}>
                    <SquareDashedIcon /> Add new
                </button>
            </div>
            <div className="floor_plan_view">
                {floorPlanStage?.length > 0 && (
                    <div className="floor_plan_top_bar">
                        <div className="title">
                            <SquareDashedIcon />
                            <input
                                value={currentFloorPlan[0]?.floor_plan_name ?? ""}
                                placeholder="New Floor Plan"
                                onChange={renameFloorPlanHandler}
                            />
                        </div>
                        <div className="floor_plan_button_group">
                            <div className="left_button_group">
                                <button onClick={undoHandler}>
                                    <UndoIcon />
                                </button>
                                <button onClick={redoHandler}>
                                    <RedoIcon />
                                </button>
                                {/* <button className="edit">
                                Edit <EditIcon />
                            </button> */}
                            </div>
                            <hr />
                            <div className="right_button_group">
                                {/* <button className="share_plan">
                                Save <SaveIcon />
                            </button> */}
                                {/* <button>
                                Comments <CommentIcon />
                            </button> */}
                                <SiteDropdown title={<ThreedotIcon />} className="custom three_dot no_hover_tick">
                                    {/* <Dropdown.Item className="no_hover_tick" onClick={exportFloorPlanAsPDF}>
                                    Download Floor Plan • (PDF) <DownloadIcon />
                                </Dropdown.Item> */}
                                    {/* <Dropdown.Item className="no_hover_tick" onClick={exportFloorPlanAsPNG}>
                                    Download Floor Plan • (PNG) <DownloadIcon />
                                </Dropdown.Item> */}
                                    {/* <Dropdown.Item>Share via email</Dropdown.Item> */}
                                    {/* <Dropdown.Item>Print</Dropdown.Item> */}
                                    <Dropdown.Item onClick={onDeleteFloorPlanHandler}>Delete Floor plan</Dropdown.Item>
                                </SiteDropdown>
                            </div>
                        </div>
                    </div>
                )}
                {floorPlanStage?.length === 0 ? (
                    <div className="floor_plan_canvas_view">
                        <div className="no_floor_plan_card">
                            <div className="image_wrapper">
                                <SquareDashedIcon />
                            </div>
                            <div className="floor_plan_text">
                                <h4>You have no Floor Plan yet :(</h4>
                                <p>
                                    You can draw your floor plan from scratch, use a template or <br />
                                    request a bespoke Plan from our in-house experts.
                                </p>
                            </div>
                            <div className="button_group">
                                {/* <button className="btn_bordered">Request a Plan</button> */}
                                <button className="btn_red" onClick={addNewFloorPlanHandler}>
                                    Create Floor Plan
                                    <PlusIcon fill="#fff" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="canvas_wrapper">
                            <div className="canvas_board" ref={canvasBoardRef} style={canvasBoardStyle}>
                                <div className="draw_actions_top">
                                    <button onClick={addTextElementHandler}>
                                        <TextIcon />
                                    </button>
                                    <button>
                                        <LinesIcon />
                                    </button>
                                </div>
                                <Stage
                                    ref={stageRef}
                                    width={dimensions.width}
                                    height={dimensions.height}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onMouseMove={handleMouseMove}
                                    onTouchStart={checkDeselect}
                                    draggable={false}
                                    onDragMove={handleDragMove}
                                    onWheel={onWheelHandler}
                                    onClick={onClickTap}
                                    onTap={onClickTap}
                                    scaleX={stageScale.scale}
                                    scaleY={stageScale.scale}
                                    style={stageStyle}>
                                    <Layer ref={layerRef}>
                                        {drawingBoardItem && <DimensionElement item={drawingBoardItem} />}
                                        {currentFloorPlan &&
                                            currentFloorPlan.length > 0 &&
                                            currentFloorPlan[0]?.stage.map((stageDetails, index) => {
                                                const isSelected = activeFloorPlanElementId === stageDetails?.id;
                                                return (
                                                    stageDetails && (
                                                        <DisplayFloorPlanElement
                                                            key={stageDetails.id}
                                                            item={stageDetails}
                                                            isSelected={isSelected}
                                                            onSelect={() => onSelectObjectHandler(stageDetails)}
                                                            onChange={(newAttrs) => {
                                                                const element = floorPlanStage[currentFloorPlanIndex].stage.slice();
                                                                element[index] = newAttrs;
                                                                transformFloorPlanElement(element, currentFloorPlanIndex);
                                                                handleChange(element);
                                                            }}
                                                            updateFloorPlanTextHandler={(textId, text) =>
                                                                updateFloorPlanTextHandler(currentFloorPlanIndex, textId, text)
                                                            }
                                                            onDragStart={(newAttrs) => {
                                                                const elements = floorPlanStage[currentFloorPlanIndex].stage.slice();
                                                                const elementItem = elements.find((i) => i.id === newAttrs.id);
                                                                if (elementItem) {
                                                                    const index = elements.indexOf(elementItem);
                                                                    elements.splice(index, 1);
                                                                    elements.push(elementItem);
                                                                }
                                                                transformFloorPlanElement(elements, currentFloorPlanIndex);
                                                                handleChange(elements);
                                                            }}
                                                        />
                                                    )
                                                );
                                            })}
                                        <Transformer
                                            ref={trRef}
                                            flipEnabled={false}
                                            boundBoxFunc={(oldBox, newBox) => {
                                                // limit resize
                                                if (newBox.width < 5 || newBox.height < 5) {
                                                    return oldBox;
                                                }
                                                return newBox;
                                            }}
                                        />
                                        <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
                                    </Layer>
                                </Stage>
                                <div className="draw_actions_bottom">
                                    <button className="percentage" onClick={resetWheelHandler}>
                                        {stageScalePercentage}%
                                    </button>
                                    <button onClick={() => fullViewHandler(true)}>
                                        <ExpandIcon />
                                    </button>
                                    <button onClick={() => fullViewHandler(false)}>
                                        <ShrinkIcon />
                                    </button>
                                    <div className="upload_wrapper">
                                        <div className="floorplan_upload_image">
                                            <UploadImageIcon />
                                            <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pick_elements">
                            <div className="pick_elements_top_bar">
                                <div className="left_view">
                                    <div className="top_bar_header">
                                        {fillSpaceElements.map((item) => {
                                            const className = item.type === pickElementStage ? "active" : "";
                                            return (
                                                <button
                                                    key={item.type}
                                                    className={`pick_element ${className}`}
                                                    onClick={() => setPickElementStage(item.type)}>
                                                    {item.icon} {item.text}
                                                </button>
                                            );
                                        })}
                                    </div>
                                    <div className="elements_group">
                                        {floorPlanElements.map((item, index) => (
                                            <button key={index} onClick={() => addFloorPlanElementHandler(item.type, item.title)}>
                                                {item.icon}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="right_view">
                                    <div className="search_tools_element">
                                        <Search fill="#D9D9D9" /> <input placeholder="Search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}
