import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePaystackPayment } from "react-paystack";
import { addMonths } from "date-fns";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import { getUserDetails } from "@/axios/get-request";
import { CurrencyList } from "@/types";
import { subscribeToPlan } from "@/axios/post-request";
import payStackConfig from "@/payStackConfig";
import { initializePaymentType, Pricing } from "@/data/types";
import "@/styles/settings.scss";

const menu = [
    { text: "Profile", link: "/new/dashboard/settings" },
    { text: "Account", link: "/new/dashboard/settings/account", active: true },
    { text: "Vendors", link: "/new/dashboard/vendors" },
];

type countryDetailsType = {
    currency: `${CurrencyList}` | "";
    country: string;
};

export default function AccountPage() {
    const [countryDetails, setCountryDetails] = useState<countryDetailsType>({
        country: "",
        currency: "",
    });
    const { data, status } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => {
            return getUserDetails();
        },
    });
    const startDate = addMonths(new Date(), 1);

    const config = payStackConfig(data?.result?.email as string, Pricing.Test_account_validity);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    async function makePaymentHandler() {
        try {
            initializePayment(() => {
                return subscribeToPlanHandler();
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function subscribeToPlanHandler() {
        await subscribeToPlan({
            email: data?.result?.email as string,
            startDate: startDate.toISOString(),
        });
    }

    useEffect(() => {
        setCountryDetails({
            ...countryDetails,
            country: data?.result?.country ?? "",
            currency: data?.result?.currency ?? "",
        });
    }, [status]);

    const accountName = data?.result?.subscription_details ? "Planday - Free trial" : "Nil";

    return (
        <NewDashboardLayout title="Settings" description="Manage your profile, workspace and more.">
            <section className="settings_page">
                <aside>
                    <ul>
                        {menu.map((item, index) => {
                            const className = item?.active ? "active" : "";
                            return (
                                <li key={index} className={className}>
                                    <Link to={item.link}>{item.text}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </aside>
                <div className="settings_view">
                    <h4>Subscription</h4>
                    <div className="profile_row">
                        <div className="text_content">
                            <p>Status</p>
                            <h6>{accountName} {data?.result?.subscription_details && <sup>Active</sup>}</h6>
                        </div>
                        {!data?.result?.subscription_details && <button onClick={makePaymentHandler}>Subscribe</button>}
                    </div>
                </div>
            </section>
        </NewDashboardLayout>
    );
}
