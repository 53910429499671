import { useEffect, useLayoutEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { InitializeReactGA } from "@/analytics";
import Calculator from "@/pages/calculator";
import BudgetTrackerPage from "@/pages/budget-tracker";
import Checklist from "@/pages/Checklist";
import Signup from "@/pages/signup";
import Login from "@/pages/login";
import MyEvents from "@/pages/myevents";
import Profile from "@/pages/profile";
import EditProfile from "@/pages/edit-profile";
import useAuthGuard from "@/hooks/useAuthGuard";
import GuardedRoute from "@/components/GuardedRoute";
import UserProfile from "@/pages/user-profile";
import AuditsPage from "@/pages/audits";
import ResetPassword from "@/pages/reset-password";
import PricingPage from "@/pages/pricing";
import OnboardingBudgetDetails from "@/pages/onboarding/budget-details";
import OnboardingEventDetails from "@/pages/onboarding/event-details";
import InviteEventCollaborator from "@/pages/onboarding/invite-event-collaborator";
import SaveOnboardingProfile from "@/pages/onboarding/save-profile";
import ViewEventBudget from "@/pages/onboarding/view-event-budget";
import CalculateEventBudget from "@/pages/onboarding/calculate-event-budget";
import AsoebiTracker from "@/pages/asoebi-tracker";
import CompleteGuestQuestionaire from "@/pages/asoebi-tracker/complete-questionnaire";
import CreateQuestionaire from "@/pages/asoebi-tracker/create-questionnaire";
import Questionnaire from "@/pages/asoebi-tracker/questionnaire";
import DashboardAuth from "@/pages/asoebi-tracker/admin-dashboard/auth";
import AsoebiAdminList from "@/pages/asoebi-tracker/admin-dashboard/asoebi-admin-list";
import AsoebiAdminPayments from "@/pages/asoebi-tracker/admin-dashboard/payment";
import AsoebiAdminQuestionnaires from "@/pages/asoebi-tracker/admin-dashboard/questionnaires";
import UserDashboard from "@/pages/asoebi-tracker/user-dashboard";
import AsoebiLogin from "@/pages/asoebi-tracker/user-dashboard/auth/login";
import QuestionnaireCompleted from "@/pages/asoebi-tracker/questionnaire-completed";
import QuestionnaireAnswered from "@/pages/asoebi-tracker/questionnaire-answered";
import AsoebiPage from "@/pages/asoebi";
import AsoebiAdminLogin from "@/pages/asoebi-tracker/user-dashboard/auth/admin-login";
import InitializeHotjar from "./InitializeHotjar";
import OrderFormDetails from "@/pages/asoebi-tracker/user-dashboard/order-form-details";
import LandingPage from "@/pages/home";
import AboutusPage from "@/pages/home/about";
import OnboardingPage from "@/pages/new/onboarding";
import AuthPage from "@/pages/new/onboarding/auth";
import OnboardingCelebrantStage1 from "@/pages/new/onboarding/celebrant/onboarding_stage_1";
import OnboardingCelebrantStage2 from "@/pages/new/onboarding/celebrant/onboarding_stage_2";
import OnboardingCelebrantStage3 from "@/pages/new/onboarding/celebrant/onboarding_stage_3";
import OnboardingEventPlannerStage1 from "@/pages/new/onboarding/event-planner/onboarding_stage_1";
import OnboardingEventPlannerStage2 from "@/pages/new/onboarding/event-planner/onboarding_stage_2";
import EventOverviewPage from "@/pages/new/dashboard/event-overview";
import NewBudgetTrackerPage from "@/pages/new/dashboard/budget-tracker";
import FloorPlan from "@/pages/new/dashboard/floor-plan";
import NewChecklistPage from "@/pages/new/dashboard/checklist";
import DocumentsPage from "@/pages/new/dashboard/documents";
import SettingsPage from "@/pages/new/dashboard/settings";
import CommunityPage from "@/pages/new/dashboard/community";
import Homepage from "@/pages/new/dashboard/index";
import SupportPage from "@/pages/new/dashboard/support";
import CelebrantPricing from "@/pages/new/pricing/celebrant";
import PlannerPricing from "@/pages/new/pricing/planner";
import VendorsPage from "./pages/new/dashboard/vendors";
import AccountPage from "./pages/new/dashboard/settings/account";
import CollaboratorsPage from "./pages/new/dashboard/collaborators";
import ActivityLog from "@/pages/new/dashboard/activity-log";
import TermOfService from "./pages/new/policy/term-of-service";
import PrivacyPolicy from "./pages/new/policy/privacy-policy";
import CollaboratorSignupPage from "./pages/new/onboarding/collaborator-signup";
import CollaboratorLoginPage from "./pages/new/onboarding/collaborator-login";
import "@/styles/index.scss";
import "@/styles/utilities.scss";
import "@/App.css";

function App() {
    const authGuard = useAuthGuard();
    const location = useLocation();

    useEffect(() => {
        InitializeReactGA(ReactGA);
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname, location.search]);

    return (
        <>
            <InitializeHotjar />
            <Routes>
                <Route path="/" element={<Calculator />} />
                <Route path="/new" element={<OnboardingPage />} />
                <Route path="/new/auth" element={<AuthPage />} />
                <Route path="/new/join-event/login" element={<CollaboratorLoginPage />} />
                <Route path="/new/join-event/signup" element={<CollaboratorSignupPage />} />
                <Route path="/new/landing-page" element={<LandingPage />} />
                <Route path="/new/onboarding-celebrant/stage-1" element={<OnboardingCelebrantStage1 />} />
                <Route path="/new/onboarding-celebrant/stage-2" element={<OnboardingCelebrantStage2 />} />
                <Route path="/new/onboarding-celebrant/stage-3" element={<OnboardingCelebrantStage3 />} />
                <Route path="/new/onboarding-event-planner/stage-1" element={<OnboardingEventPlannerStage1 />} />
                <Route path="/new/onboarding-event-planner/stage-2" element={<OnboardingEventPlannerStage2 />} />
                <Route path="/new/celebrant/pricing" element={<CelebrantPricing />} />
                <Route path="/new/planner/pricing" element={<PlannerPricing />} />
                <Route path="/new/term-of-service" element={<TermOfService />} />
                <Route path="/new/privacy-policy" element={<PrivacyPolicy />} />

                {/* New Dashboard */}
                <Route element={<GuardedRoute isRouteAccessible={authGuard} redirectRoute="/new" />}>
                    <Route path="new/dashboard" element={<Homepage />} />
                    <Route path="new/dashboard/event-overview" element={<EventOverviewPage />} />
                    <Route path="new/dashboard/documents" element={<DocumentsPage />} />
                    <Route path="new/dashboard/settings" element={<SettingsPage />} />
                    <Route path="new/dashboard/settings/account" element={<AccountPage />} />
                    <Route path="new/dashboard/collaborators" element={<CollaboratorsPage />} />
                    <Route path="new/dashboard/support" element={<SupportPage />} />
                    <Route path="new/dashboard/community" element={<CommunityPage />} />
                    <Route path="new/dashboard/activity-log" element={<ActivityLog />} />
                    <Route path="new/dashboard/floor-plan" element={<FloorPlan />} />
                    <Route path="new/dashboard/checklist" element={<NewChecklistPage />} />
                    <Route path="new/dashboard/budget-tracker" element={<NewBudgetTrackerPage />} />
                    <Route path="new/dashboard/vendors" element={<VendorsPage />} />
                </Route>

                <Route path="/new/about-us" element={<AboutusPage />} />
                <Route path="/budget" element={<Calculator />} />
                <Route path="/onboarding/event-details" element={<OnboardingEventDetails />} />
                <Route path="/onboarding/budget-details" element={<OnboardingBudgetDetails />} />
                <Route path="/onboarding/calculate-event-budget" element={<CalculateEventBudget />} />
                <Route path="/onboarding/view-event-budget" element={<ViewEventBudget />} />
                <Route path="/onboarding/invite-event-collaborator" element={<InviteEventCollaborator />} />
                <Route path="/onboarding/save-profile" element={<SaveOnboardingProfile />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/signup" element={<Signup />} />

                {/* asoebi questionnaire organizer */}
                <Route path="/asoebi" element={<AsoebiPage />} />
                <Route path="/asoebi/track-orders" element={<AsoebiTracker type="tracker" />} />
                <Route path="/asoebi-tracker" element={<AsoebiTracker />} />
                <Route path="/asoebi-tracker/update-order-form" element={<AsoebiTracker />} />
                <Route path="/asoebi-tracker/create-questionnaire" element={<CreateQuestionaire />} />
                <Route path="/asoebi-tracker/complete-questionnaire" element={<CompleteGuestQuestionaire />} />
                <Route path="/asoebi-tracker/questionnaire-completed" element={<QuestionnaireCompleted />} />
                <Route path="/asoebi-tracker/questionnaire-answered" element={<QuestionnaireAnswered />} />
                <Route path="/asoebi-tracker/:id/:id" element={<Questionnaire />} />

                {/* asoebi planner auth */}
                <Route path="/asoebi/login" element={<AsoebiLogin />} />
                <Route path="/asoebi/admin-login" element={<AsoebiAdminLogin />} />

                {/* asoebi planner dashboard */}
                <Route path="/asoebi/dashboard" element={<UserDashboard />} />
                <Route path="/asoebi/dashboard/order-form" element={<OrderFormDetails />} />

                {/* asoebi admin dashboard */}
                <Route path="/admin/asoebi/login" element={<DashboardAuth />} />
                <Route path="/admin/asoebi/dashboard/questionnaires" element={<AsoebiAdminQuestionnaires />} />
                <Route path="/admin/asoebi/dashboard/payments" element={<AsoebiAdminPayments />} />
                <Route path="/admin/asoebi/dashboard/admin" element={<AsoebiAdminList />} />

                {/* protected routes */}
                <Route element={<GuardedRoute isRouteAccessible={authGuard} redirectRoute="/login" />}>
                    <Route path="/dashboard" element={<MyEvents />} />
                    <Route path="/dashboard/budget-tracker" element={<BudgetTrackerPage />} />
                    <Route path="/dashboard/checklist" element={<Checklist />} />
                    <Route path="/dashboard/edit-profile" element={<EditProfile />} />
                    <Route path="/dashboard/my-events" element={<Profile />} />
                    <Route path="/dashboard/events" element={<UserProfile />} />
                    <Route path="/dashboard/audits" element={<AuditsPage />} />
                    <Route path="/dashboard/pricing" element={<PricingPage />} />
                </Route>
            </Routes>
        </>
    );
}

export default App;
