import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FuzzySearch from "fuzzy-search";
import { Dropdown } from "react-bootstrap";
import type { ChangeEvent } from "react";

import { getAudits } from "@/axios/get-request";
import Search from "@/assets/icon/Search";
import CancelIcon from "@/assets/icon/Cancel";
import Loader from "@/components/loader";
import BudgetTrackerAuditDropdown from "@/components/BudgetTrackerAuditDropdown";
import { useEvent } from "@/hooks/useEvent";
import AuditsLoader from "@/components/loaders/AuditsLoader";
import { AuditType } from "@/data/types";
import GeneralAuditDropdown from "@/components/GeneralAuditDropdown";
import ChecklistAuditDropdown from "@/components/ChecklistAuditDropdown";
import type { auditType } from "@/types";
import ArrowUp from "@/assets/icon/ArrowUp";
import ArrowDown from "@/assets/icon/ArrowDown";
import DefaultView from "@/components/DefaultView";
import FilterDropdown from "@/components/FilterDropdown";
import "@/styles/audits.scss";

type filterOptionsType = "All" | "Budget tracker" | "Checklist" | "General";
const filterOptions = ["All", "Budget tracker", "Checklist", "Floor plan", "General"];

// const selectLimitDetails = {
//     label: "",
//     name: "events",
//     options: [5, 10, 15, 20, 50],
// };

export default function ViewActivityLog() {
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pageCounter, setPageCounter] = useState(1);
    const [searchAudit, setSearchAudit] = useState<auditType>([]);
    const [filter, setFilter] = useState<filterOptionsType>("All");
    const { selectedEventId } = useEvent();
    const [limit] = useState(5);
    const { data, status } = useQuery({
        queryKey: [`get_audits_${selectedEventId}`],
        queryFn: () => getAudits(selectedEventId),
    });

    const audits = data?.data?.result ? data?.data?.result : [];

    function setSearchHandler(e: ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        if (audits.length > 0 && status === "success") {
            const recent_audits = audits && audits.length > 0 ? audits?.reverse() : [];
            setSearchAudit([...recent_audits]);
        }
    }, [status]);

    useEffect(() => {
        if (searchText) {
            setIsSearching(true);
            const searcher = new FuzzySearch(searchAudit, ["title"]);
            const result = searcher.search(searchText);
            setSearchAudit(result);
            if (result) {
                setIsSearching(false);
            }
        } else {
            const audits_array = data?.data?.result ? data?.data?.result : [];
            const recent_audits_array = audits_array?.reverse();
            setSearchAudit([...recent_audits_array]);
        }
    }, [searchText.length]);

    function cancelSearchHandler() {
        setSearchText("");
        setIsSearching(false);
        const audits_array = data?.data?.result ? data?.data?.result : [];
        const recent_audits_array = audits_array?.reverse();
        setSearchAudit([...recent_audits_array]);
    }

    // function onSelectEventLimit(e: ChangeEvent<HTMLSelectElement>) {
    //     setLimit(Number(e.target.value));
    // }

    function incrementPageCounter() {
        setPageCounter((prev) => prev + 1);
    }

    function decrementPageCounter() {
        if (pageCounter > 1) {
            setPageCounter((prev) => prev - 1);
        }
    }

    function filterAudit(value: string) {
        const eventValue = value as filterOptionsType;
        setFilter(eventValue);
    }

    const auditMin = pageCounter === 1 ? 0 : limit * (pageCounter - 1);
    const auditMax = pageCounter === 1 ? limit * pageCounter : limit * pageCounter;
    const filterAuditCondition =
		filter === "Budget tracker" ? AuditType.budget_tracker : filter === "Checklist" ? AuditType.checklist : AuditType.general;
    const filterAuditArray = filter !== "All" ? searchAudit.filter((item) => item.type === filterAuditCondition) : searchAudit;
    const paginatedAudit = filterAuditArray.slice(auditMin, auditMax);
    const showingAuditType = filter === "All" ? searchAudit : filterAuditArray;
    const showingAuditCounter = limit * pageCounter < showingAuditType.length ? limit * pageCounter : showingAuditType.length;
    const totalAudits = filterAuditArray.length;
    const disableLeftArrowBtn = (totalAudits && totalAudits <= limit) || pageCounter === 1 ? true : false;
    const leftArrowColor = disableLeftArrowBtn ? "gray" : "black";
    const disableRightArrowBtn = totalAudits && totalAudits <= limit * pageCounter ? true : false;
    const rightArrowColor = disableRightArrowBtn ? "gray" : "black";

    return (
        <section className="activity_log">
            <div className="d-flex flex-md-row mt-1 flex-column align-items-md-center justify-content-md-between">
                <div className="log_title"></div>
                <div className="audit_actions">
                    <div className="merged_buttons">
                        <FilterDropdown title={filter}>
                            {filterOptions.map((item, index) => (
                                <Dropdown.Item key={index} onClick={() => filterAudit(item)}>
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </FilterDropdown>
                    </div>
                    <div id="search_audits" className="col-md-4 col-12 p-0 search_audits position-relative search_form mr-0 mb-2 mb-lg-0">
                        <input placeholder="Search" className="form-control" value={searchText} onChange={setSearchHandler} />
                        {!isSearching && (
                            <div className="search_cta">
                                {!searchText ? (
                                    <button className="position-absolute border-0 bg-transparent" type="button">
                                        <Search fill="#545565" />
                                    </button>
                                ) : (
                                    <button className="position-absolute border-0 bg-transparent" type="button" onClick={cancelSearchHandler}>
                                        <CancelIcon />
                                    </button>
                                )}
                            </div>
                        )}
                        {isSearching && (
                            <span className="position-absolute search_spinner">
                                <Loader mode="dark" />
                            </span>
                        )}
                    </div>

                    {/* <Select
                            input={filterInput}
                            value={filter}
                            className="mt-3 filter_audit_select"
                            options={filterOptions}
                            onChange={filterAudit}
                        /> */}
                </div>
            </div>

            {status === "error" ? (
                <p>Unable to fetch audit</p>
            ) : status === "loading" ? (
                <AuditsLoader />
            ) : data?.data.result?.length === 0 || !data?.data?.result ? (
                <DefaultView title="No Activity Log" description="Start planning to begin tracking activity." />
            ) : (
                <div className="logs">
                    <div className="mt-5 log_group">
                        {paginatedAudit.map((audit) => {
                            return audit.type === AuditType.general ? (
                                <GeneralAuditDropdown key={audit.id} audit={audit} showType={filter !== "General"} />
                            ) : audit.type === AuditType.checklist ? (
                                <ChecklistAuditDropdown key={audit.id} audit={audit} showType={filter !== "Checklist"} />
                            ) : (
                                <BudgetTrackerAuditDropdown key={audit.id} audit={audit} showType={filter !== "Budget tracker"} />
                            );
                        })}
                    </div>
                    {data?.data?.result.length > 0 && showingAuditCounter > 0 && (
                        <div className="paginate_event mt-lg-5 mt-3 pt-lg-5 pt-3 w-full d-flex justify-content-between align-items-center">
                            <div className="pagination_btn_group">
                                <button className="bg-white mr-2" disabled={disableLeftArrowBtn} onClick={decrementPageCounter}>
                                    <ArrowUp fill={leftArrowColor} />
                                </button>
                                <button className="bg-white ml-2" disabled={disableRightArrowBtn} onClick={incrementPageCounter}>
                                    <ArrowDown fill={rightArrowColor} />
                                </button>
                            </div>
                            <div className="d-flex align-items-center">
                                {/* <Select value={limit} onChange={onSelectEventLimit} input={selectLimitDetails} /> */}
                                <p className="mb-0 ml-2">
										Showing {showingAuditCounter} of {totalAudits}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </section>
    );
}
