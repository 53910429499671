import type { breakdownType, FloorPlanObjectListType } from "@/data/types";
import type { ChangeEvent } from "react";
import type { MultiValue } from "react-select";

export type todoType = Array<{
    isDone: boolean;
    due: Date | null;
    id: string;
    name: string;
    note: string | null;
    priority?: string;
    ceremony: { name: string; id: string };
}>;

export type TodoType = Array<{
	isDone: boolean;
	due: Date;
	id: string;
	name: string;
	note: string | null;
	priority?: string;
	ceremony: { name: string; id: string };
}>;


type checklistModalType = "add_checklist_modal" | "edit_checklist_modal" | "delete_checklist_modal" | null;

export type useChecklistType = {
    checklistId: string;
    updateChecklistId: (value: string) => void;
    todo: todoType;
    setTodo: (value: todoType) => void;
    checklistModal: checklistModalType;
    setChecklistModal: (value: checklistModalType) => void;
    selectectedChecklist: null | todoType[0];
    setSelectectedChecklist: (value: todoType[0] | null) => void;
};

export type onboardingDetailsType = {
    name: string;
    location: string;
    no_of_guests: string;
    event_name: string;
    event_date: "" | Date;
    ceremonies: MultiValue<{ label: string; value: string }>;
};

export type onboardingBudgetEstimateType = {
    breakdown: Array<{
        item_code: string;
        item: string;
        cost_standard: number;
        cost_mid_luxury: number;
        cost_luxurious: number;
        checked: boolean;
        category: string;
    }>;
    budget_category: string;
};

export type checklistAuditChanges = { from?: todoType[0]; to?: todoType[0] };
export type budgetTrackerAuditChanges = {
    from: costItemType | null;
    to: costItemType | null;
};

export type ceremonyAuditChange = {
    from: { name: string; location: string; guest: number | null; date: null | Date };
    to: { name: string; location: string; guest: number | null; date: null | Date };
};

type duplicateEventType = { duplicate_event: string };

export enum auditActionType {
    "add_checklist" = "add_checklist",
    "delete_checklist" = "delete_checklist",
    "update_checklist" = "update_checklist",
    "add_budget_cost_item" = "add_budget_cost_item",
    "delete_budget_cost_item" = "delete_budget_cost_item",
    "update_budget_cost_item" = "update_budget_cost_item",
    "checklist_completed" = "checklist_completed",
    "change_ceremony" = "change_ceremony",
    "checklist_not_completed" = "checklist_not_completed",
    "add_ceremony" = "add_ceremony",
    "invite_collaborator" = "invite_collaborator",
    "add_event" = "add_event",
    "duplicate_event" = "duplicate_event",
    "add_budget_cost_item_category" = "add_budget_cost_item_category",
    "vendor_assistance_service_request" = "vendor_assistance_service_request",
}

export type auditType = Array<{
    id: string;
    user_id: string;
    name: string;
    email: string;
    type: "checklist" | "budget_tracker" | "general";
    action: keyof typeof auditActionType;
    title: string;
    timestamp: Date;
    changes?: checklistAuditChanges | budgetTrackerAuditChanges | ceremonyAuditChange | duplicateEventType | null;
    metadata?: { [key: string]: string | number | boolean };
}>;

export enum EventPlanType {
    FREE = "FREE",
    BASIC = "BASIC",
    ESSENTIAL = "ESSENTIAL",
    PREMIUM = "PREMIUM",
    CUSTOM = "CUSTOM",
}

export enum EventPlanStatus {
    ACTIVE = "ACTIVE",
    EXPIRED = "EXPIRED",
}

export enum CollaboratorPlanStatus {
    ACTIVE = "ACTIVE",
    EXPIRED = "EXPIRED",
}

export enum CollaboratorPaymentPlan {
    FREE = "FREE",
    PAID = "PAID",
}

export enum PLAN_PAYMENT_TYPE {
    ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
    MONTHLY = "MONTHLY",
}

export enum Asoebi_order_status {
    PENDING = "PENDING",
    SHIPPED = "SHIPPED",
    DELIVERED = "DELIVERED",
}

export enum UserList {
    celebrant = "celebrant",
    planner = "planner",
}

export enum SocialsList {
    Instagram = "Instagram",
    Tiktok = "Tiktok",
}

export enum CurrencyList {
    NAIRA = "NGN",
    CEDIS = "GHS",
    DOLLAR = "USD",
    POUNDS = "GBP",
}

export type CurrencyType = `${CurrencyList}`;

export type userType = {
    createdAt: string;
    days?: number;
    hasBudget?: boolean;
    role?: string;
    display_name: string;
    event_ids: string[];
    email: string;
    email_verified: boolean;
    id: string;
    is_email_verified: boolean;
    name: string;
    business_name?: string;
    business_image?: string;
    social_media?: Array<{ type: keyof typeof SocialsList; link: string }>;
    phone_number?: string;
    website?: string;
    country?: string;
    currency?: CurrencyType;
    tour_guide: {
        user_profile: boolean;
        event_overview: boolean;
        budget_tracker: boolean;
        checklist: boolean;
        homepage?: boolean;
        floor_plan?: boolean;
    };
    user_type?: keyof typeof UserList;
    photo_url: string;
    profile_photo: string;
    timestamp: string;
    uid: string;
    onboarding?: {
        id?: string;
        proceed_from_calculator_to_onboarding?: boolean;
        calculator_details?: {
            id: string;
            role: string;
            budget: number;
            hasBudget: boolean;
            days: number;
            data: Array<{
                id: number;
                location: string;
                no_of_guests: number;
            }>;
        };
        onboarding_details?: {
            name: string;
            location: string;
            no_of_guests: number;
            event_id?: string;
            event_name: string;
            event_date: Date | "";
            business_name?: string;
            phone_number?: string;
            website?: string;
            ceremonies: MultiValue<{ label: string; value: string }> | Array<{ type: string; location: string; no_of_guests: string }>;
        };
        download_budget?: boolean;
        skip_budget_breakdown?: boolean;
        onboarding_stage?: number;
        user_name?: string | null;
        email?: string | null;
        completed?: boolean;
        invited?: boolean;
    };
    paystack_customer_details?: {
        first_name?: string;
        last_name?: string;
        email?: string;
        phone?: string;
        metadata: null;
        domain?: string;
        customer_code?: string;
        risk_action?: string;
        id: number;
        integration: number;
        createdAt?: string;
        updatedAt?: string;
        created_at?: string;
        updated_at?: string;
        total_transactions: number;
        total_transaction_value?: [];
        dedicated_account: string | null;
        dedicated_accounts: [];
        identified: boolean;
        identifications: Array<string> | null;
    };
    subscription_details?: {
        customer: number;
        plan: number;
        integration: number;
        domain: string;
        start: number;
        status: string;
        quantity: number;
        amount: number;
        authorization: {
            authorization_code: string;
            bin: string;
            last4: string;
            exp_month: string;
            exp_year: string;
            channel: string;
            card_type: string;
            bank: string;
            country_code: string;
            brand: string;
            reusable: boolean;
            signature: string;
            account_name: string;
        };
        subscription_code: string;
        email_token: string;
        id: number;
        createdAt: string;
        updatedAt: string;
    };
    action?: {
        signup_email_sent?: boolean;
        close_one_time_vendor_assistance_modal?: boolean;
    };
};

export enum RequestType {
    Success = "Success",
    Error = "Error",
}

export type pricingPlanPaymentType = keyof typeof EventPlanType;

export type GetUserResponse = {
    status: number;
    message: string;
    result: userType | null;
    type: keyof typeof RequestType;
};

export type GetUserChecklist = {
    data: {
        message: string;
        checklist: todoType;
    };
};

export type GetBudgetResponseType = {
    data: {
        message: string;
        result: budgetType;
    };
};

export type RequestErrorType = {
    response: {
        data: {
            message: string;
        };
    };
};

export type VendorType = {
    name: string;
    id: string;
    service: string;
    email: string;
    user_id: string;
    event_id: Array<string>;
    phone_number: string;
    account_number: string;
    bank_name: string;
    bank_code: string;
};

export type costItemType = {
    name: string;
    estimate: number;
    actual: number;
    paid: number;
    balance: number;
    paid_by_me: string;
    vendor_name?: string;
    note: string;
    vendor_ig: string;
    balance_due: Date | null;
    tag: string;
    deliverables?: Partial<{ name: string; amount: string }>;
    id: string;
    vendor?: Partial<VendorType> | null;
};

export type categoryType = {
    id: string;
    name: string;
    cost_items: costItemType[];
    estimate_total: number;
    actual_total: number;
    paid_total: number;
};

export type budgetType = {
    categories: categoryType[];
    actual_cost: number;
    estimate: number;
    paid: number;
};

export type costItemObjectType = {
    categoryId: string;
    cost_items: costItemType[];
};

export type ceremonyType = Array<{
    id: string;
    name: string;
    location: string | null;
    no_of_guests: string | number | null;
    date: Date | null;
    checklist?: todoType;
    image?: string;
    show_suggestion: boolean;
}>;

export type eventWeatherArchiveType = {
    latitude: number;
    longitude: number;
    generationtime_ms: number;
    utc_offset_seconds: number;
    timezone: string;
    timezone_abbreviation: string;
    elevation: number;
    daily_units: {
        time: string;
        weathercode: string;
        temperature_2m_mean: string;
    };
    daily: {
        time: string[];
        weathercode: number[];
        temperature_2m_mean: number[];
    };
};

export type ceremonyDataFromCalculatorType = Array<{
    id: string;
    location: string | null;
    no_of_guests: number | null;
    name: string;
    date: Date | null;
    checklist: [];
    show_suggestion: boolean;
}>;

export type weddingCalculatorDetailsType = {
    form: {
        role: string | "Groom" | "Bride";
        budget: number;
        hasBudget: boolean;
        days: number;
    };
    data: Array<{
        id: string;
        location: string;
        no_of_guests: number;
        name: string;
    }>;
};

export type budgetTrackerfuncType = {
    editBudgetTitleHandler: () => void;
    setBudgetTitleHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    onAmountChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onFormElementChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSelectDate: (value: Date) => void;
    saveCostItem: () => void;
    closeModal: () => void;
    showDeleteModal: () => void;
    updateCheckBox: () => void;
    showModal: () => void;
    onSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    toggleDropdown: () => void;
    autoSaveEstimateAndActuals: () => void;
};

export type exportBudgetType = {
    guests: number;
    user: string;
    locations: string;
    number_of_days: number;
    email_address: string;
    export_type: string;
    budget_breakdown: breakdownType[];
    budget_id: string;
    user_comms_subscribed: boolean;
    help_find_vendor: boolean;
};

export type pricingPlanType = "basic" | "essential" | "premium" | "";

export type CollaboratorType = {
    email: string;
    role: string;
    name?: string;
    id?: string;
    event_ids: string[];
    permission?: string;
    plan_duration: null | number;
    payment_plan: keyof typeof CollaboratorPaymentPlan;
    plan_status: keyof typeof CollaboratorPlanStatus;
    send_plan_expiration_email: boolean;
    start_plan_date: string;
    end_plan_date: string | null;
};

export type eventType = {
    id: string;
    collaborators: Array<CollaboratorType>;
    location?: string;
    budget?: budgetType;
    budgetAmount?: number;
    ceremonies: ceremonyType;
    audits?: auditType;
    event_date?: Date | string;
    event_name: string;
    no_of_guests?: number;
    plan_duration_month: number | null;
    plan_amount: number | null;
    max_events: number;
    expired_collaborators_ids?: string[];
    plan: keyof typeof EventPlanType;
    plan_payment_type: keyof typeof PLAN_PAYMENT_TYPE | null;
    event_image?: string;
    event_owner?: {
        email: string;
        name?: string;
        id: string;
        role: string;
        event_id: string;
        user_type?: string;
    };
    floor_plan?: FloorPlanType;
    event_bg?: string;
    plan_status: keyof typeof EventPlanStatus;
    collaborator_per_events: number | null;
    collaborator_ids: string[];
    createdAt?: string;
};

export enum PaymentType {
    EVENT = "EVENT",
    COLLABORATOR = "COLLABORATOR",
    VENDOR_ASSISTANCE = "VENDOR_ASSISTANCE",
}

export type verifyPaymentType = {
    reference_id: string;
    user_id: string;
    event_id: string;
    type: keyof typeof PaymentType;
    amount: number;
    actual_amount: number;
};

export type update_audit_type = "checklist" | "budget_tracker" | "general";

export type ceremonyLocationDataType = Array<{
    id: number;
    location: string;
    no_of_guests: number;
}>;

export type onboardingFormDetailsType = {
    name: string;
    location: string;
    no_of_guests: string;
    event_name: string;
    event_date: "" | Date;
    ceremonies: MultiValue<{ label: string; value: string }>;
};

export type onboardingDetailsStorageType = onboardingFormDetailsType & { event_id: string };

export type AsoebiQuestionnaireType = {
    eventDetails: {
        name: string;
        email: string;
        phone_number: string;
        event_name: string;
        accountName: string;
        accountNumber: string;
        bankCode: string;
        bankName: string;
        bankSlug: string;
    };
    guestDetails: {
        name: boolean;
        phone_number: boolean;
        collection_option: string;
        delivery_address: boolean;
        upload_media_type: string;
        pickup_address: boolean;
        pickup_phone_number: boolean;
        order_update: string;
    };
    asoebiOptions: {
        mode_of_communication: string;
        email_address_for_communication: string;
        whatsapp_number: string;
        special_note: boolean;
        order_deadline: string;
        currency: string;
        options: Array<{ option: string; price: string; qty: number; max_stock_qty: number; stock_bought_qty: number }>;
        images?: string[];
    };
};

export type AsoebiOrderFormType = AsoebiQuestionnaireType & {
    created_at: string;
    id: string;
    questionnaire_link: string;
    vendor_slug: string;
    no_of_paid_guest: number;
    total_amount_paid: number;
};

export type AsoebiOrderType = {
    id: string;
    email: string;
    questionnaire_id: string;
    vendor_slug: string;
    vendor_id: string;
    questionnaire_link: string;
    paid_at: string;
    amount_paid: string;
    is_payment_verified: boolean;
    name: string;
    status: keyof typeof Asoebi_order_status;
    order_deadline: string;
    paystack_reference_id: string;
    paystack_doc_id: string;
    phone_number: string;
    pickup_address?: string;
    pickup_phone_number?: string;
    delivery_address?: string;
    special_note: string;
    created_at: string;
    event_name: string;
    options: [
        {
            option: string;
            price: string;
            qty: number;
        },
    ];
};

export type bankListType = Array<{
    id: number;
    name: string;
    slug: string;
    code: string;
    longcode: string;
    gateway: string;
    pay_with_bank: boolean;
    supports_transfer: boolean;
    active: boolean;
    country: string;
    currency: string;
    type: string;
    is_deleted: boolean;
    createdAt: string;
    updatedAt: string;
}>;

export type resolveBankDetailsType = { account_name: string; account_number: string };

export type submitAsoebiTrackerPayloadType = {
    email: string;
    options: Array<{ option: string; price: string; qty: number }>;
    name: string;
    phone_number: string;
    delivery_address?: string;
    pickup_address?: string;
    pickup_phone_number?: string;
    special_note?: string;
    amount_paid: string;
    vendor_slug?: string;
    questionnaire_id?: string;
    event_name?: string;
    event_owner_name?: string;
    event_owner_email?: string;
    questionnaire_link?: string;
};

export type submitAsoebiPayloadType = submitAsoebiTrackerPayloadType & {
    paystack_reference_id: string;
    paystack_doc_id: string;
};

export type stageType = Array<{
    x: number;
    y: number;
    height: number;
    width: number;
    fill: string;
    id: string;
    text?: string;
    type: FloorPlanObjectListType;
    image?: string;
}>;

export type FloorPlanType = Array<{
    floor_plan_name: string;
    id: string;
    floor_plan_icon: string;
    active_object_type?: string | null;
    stage: stageType | [];
    createdAt?: string;
}>;
