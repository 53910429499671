import { Suspense, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import DeleteModal from "@/components/modals/DeleteModal";
import useToast from "@/hooks/useToast";
import { deleteCostItem } from "@/axios/delete-request";
import { useBudgetTracker } from "@/hooks/useBudgetTracker";
import { useModal } from "@/hooks/useModal";
import { useEvent } from "@/hooks/useEvent";

interface Props {
    deleteCostItemHandler: (id: string) => void;
}

export default function DeleteBudgetCostItemModal({ deleteCostItemHandler }: Props) {
    const queryClient = useQueryClient();
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("deleteCostItem");
    const { modal, setModal } = useModal();
    const { selectedEventId } = useEvent();
    const { selectedCostItem, setSelectedCostItem } = useBudgetTracker();

    function closeDeleteModal() {
        setModal(null);
        setSelectedCostItem(null);
    }

    async function onDeleteCostItemHandler() {
        if (selectedCostItem) {
            return await deleteCostItem(selectedCostItem.id, selectedCostItem.categoryId, selectedEventId);
        }
    }

    const { mutate } = useMutation({
        mutationKey: ["deleteCostItem"],
        mutationFn: () => onDeleteCostItemHandler(),
        onMutate: () => {
            if (selectedCostItem) {
                loadingToast(toastId);
            }
        },
        onSuccess: (data) => {
            if (selectedCostItem) {
                deleteCostItemHandler(selectedCostItem?.id);
                updateToast(toastId, "success", data?.data?.message);
                queryClient.resetQueries({ queryKey: [`get_categories_${selectedEventId}`] });
                closeDeleteModal();
            }
        },
        onError: (error: unknown) => {
            const _error = error as { message: string };
            const _errorMessage = _error?.message || "oops an error occured, unable to delete cost item";
            updateToast(toastId, "error", _errorMessage);
        },
    });
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {selectedCostItem && (
                <DeleteModal show={modal === "delete_modal"} onHide={closeDeleteModal} title={selectedCostItem?.name} onDelete={mutate} />
            )}
        </Suspense>
    );
}
