import { ChangeEvent, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePaystackPayment } from "react-paystack";
import type { SingleValue } from "react-select";

import SiteModal from "@/components/modals/SiteModal";
import { vendorAssistanceRequest, verifyPayment } from "@/axios/post-request";
import Button from "@/components/Button";
import vendorAssistanceForm from "@/json/form/vendor_assistance_form.json";
import Input from "@/components/form/Input";
import Select from "@/components/form/Select";
import VendorDetails from "@/components/form/VendorDetails";
import Textarea from "@/components/form/Textarea";
import useUser from "@/hooks/useUser";
import payStackConfig from "@/payStackConfig";
import { initializePaymentType, paystackReferenceType } from "@/data/types";
import { getUID } from "@/axios/config";
import { PaymentType } from "@/types";
import { PAYSTACK_CURRENCY_CONSTANT, VENDOR_ASSISTANCE_PRICING,
    VENDOR_ASSISTANCE_WHATSAPP_MESSAGE, VENDOR_ASSISTANCE_WHATSAPP_MESSAGE_DESKTOP } from "@/utils/constant";
import { validateEmail } from "@/helper";
import { updateUserDetails } from "@/axios/put-request";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface Props {
    show: boolean;
    onHide: () => void;
    showOnceOnHomepage?: boolean;
}

type formDetailsType = {
    name: string;
    email: string;
    phoneNumber: string;
    communicationMethod: string;
    vendorRequirement: string;
    vendorPlans: string;
};

type vendorDetailsItemType = Array<{
    vendorService: string;
    eventLocation: string;
    vendorEstimatedBudget: string;
    eventDate?: Date | null;
    otherServices: boolean;
}>;

/**
 * @param show
 * @param onHide
 * @returns JSX
 */

export default function VendorAssistanceModal({ show, onHide, showOnceOnHomepage }: Props) {
    const [showPricing, setShowPricing] = useState(false);
    const { data, selectedEventId } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const tablet = useMediaQuery("(max-width: 768px)");
    const queryClient = useQueryClient();
    const [formDetails, setFormDetails] = useState<formDetailsType>({
        name: "",
        email: "",
        phoneNumber: "",
        communicationMethod: "",
        vendorRequirement: "",
        vendorPlans: "",
    });
    const [vendorDetails, setVendorDetails] = useState<vendorDetailsItemType>([
        { vendorService: "", eventLocation: "", vendorEstimatedBudget: "", eventDate: null, otherServices: false },
    ]);

    const amount =
        vendorDetails.length <= 2 ?
            VENDOR_ASSISTANCE_PRICING.one_vendor.unit_price :
            vendorDetails.length >= 3 && vendorDetails.length <= 4 ?
                VENDOR_ASSISTANCE_PRICING.three_vendors.unit_price :
                vendorDetails.length >= 5 ?
                    VENDOR_ASSISTANCE_PRICING.five_vendors.unit_price :
                    VENDOR_ASSISTANCE_PRICING.five_vendors.unit_price;

    const qty = vendorDetails.length;
    const actual_vendor_assistance_amount = amount * qty;
    const amountPaid = amount * qty;
    const email = data?.result?.email as string;
    const config = payStackConfig(email, amountPaid);

    const initializePayment = usePaystackPayment(config) as initializePaymentType;
    const { mutate } = useMutation({
        mutationKey: ["vendorAssistance"],
        mutationFn: () => vendorAssistanceRequest({ ...formDetails, vendorDetails, amountPaid: amountPaid.toLocaleString("en-US") }),
        onSuccess: () => {
            setIsLoading(false);
            toast.success("Thanks for requesting for a vendor assistance");
            onHideModal();
        },
        onError: () => {
            setIsLoading(false);
            toast.error("Error submitting your request for a vendor assistance");
        },
    });

    function onUpdateVendorDetails(event: ChangeEvent<HTMLInputElement | HTMLSelectElement> | null, idx: number, date?: Date | null) {
        const selectedVendorDetailsIndex = vendorDetails.findIndex((_, index) => index == idx);
        let vendorDetailsForm = event ? { [event.target.name]: event.target.value } : { eventDate: date };

        if (event?.target.name === "vendorEstimatedBudget") {
            const updateValue = event?.target.value.replace(/,/g, "");
            const formattedvendorEstimatedBudget = updateValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            vendorDetailsForm = { vendorEstimatedBudget: formattedvendorEstimatedBudget };
        }

        vendorDetails[selectedVendorDetailsIndex] = {
            ...vendorDetails[selectedVendorDetailsIndex],
            ...vendorDetailsForm,
        };
        setVendorDetails([...vendorDetails]);
    }

    function onAddMoreVendorDetails() {
        if (vendorDetails.length <= 10) {
            setVendorDetails([
                ...vendorDetails,
                { vendorService: "", eventLocation: "", vendorEstimatedBudget: "", eventDate: null, otherServices: false },
            ]);
        } else {
            return toast.error("You can add maximum of 10 request for vendor assistance.");
        }
    }

    function onReduceVendorDetails(idx: number) {
        const updateVendorDetails = vendorDetails.filter((_, index) => index !== idx);
        setVendorDetails(updateVendorDetails);
    }

    function onClose() {
        toast.error("Unable to make payment");
    }

    async function onSuccess(reference: paystackReferenceType) {
        setIsLoading(true);
        const uid = getUID();
        const verifyPaymentRequest = await verifyPayment({
            reference_id: reference.reference,
            event_id: selectedEventId,
            user_id: uid,
            type: PaymentType.VENDOR_ASSISTANCE,
            amount: amount * PAYSTACK_CURRENCY_CONSTANT,
            actual_amount: actual_vendor_assistance_amount * PAYSTACK_CURRENCY_CONSTANT,
        });
        if (verifyPaymentRequest.status === 500 || !verifyPaymentRequest.data.status) {
            return toast.error(verifyPaymentRequest.data.message);
        }
        return mutate();
    }

    function submitHandler(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        let unfilledFieldsArray = [];
        if (!formDetails.name) {
            return toast.error("name is required");
        }
        if (!formDetails.email) {
            return toast.error("Email is required");
        }
        if (!validateEmail(formDetails.email)) {
            return toast.error("Valid Email is required!");
        }
        if (!formDetails.phoneNumber) {
            return toast.error("Phone number is required!");
        }
        if (!formDetails.communicationMethod) {
            return toast.error("Communication method is required!");
        }
        vendorDetails.map((vendorDetail) => {
            if (!vendorDetail.eventDate || !vendorDetail.eventLocation || !vendorDetail.vendorEstimatedBudget || !vendorDetail.vendorService) {
                unfilledFieldsArray.push(vendorDetail);
            } else {
                unfilledFieldsArray = [];
            }
        });
        if (unfilledFieldsArray.length > 0) {
            return toast.error("Please ensure to fill the vendor assistance row details");
        }
        initializePayment((response) => onSuccess(response), onClose);
    }

    function onFormElementChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        setFormDetails({
            ...formDetails,
            [event.target.name]: event.target.value,
        });
    }

    function onUpdateOptionsPicker(_vendorAssistanceService: unknown, index: number) {
        const selectedVendorDetailsIndex = vendorDetails.findIndex((_, idx) => idx == index);
        const vendorAssistanceService = _vendorAssistanceService as SingleValue<{
            label: string;
            value: string;
        }>;
        const vendorAssistanceServiceValue = vendorAssistanceService?.value as string;
        const otherServices = vendorAssistanceServiceValue === "Other" ? { otherServices: true } : {};

        vendorDetails[selectedVendorDetailsIndex] = {
            ...vendorDetails[selectedVendorDetailsIndex],
            ...otherServices,
            vendorService: vendorAssistanceServiceValue,
        };
        setVendorDetails([...vendorDetails]);
    }

    async function onHideModal() {
        onHide();
        if (showOnceOnHomepage && data?.result?.id) {
            const existingActions = data?.result?.action ? data?.result?.action : {};
            await updateUserDetails(data.result.id, {action: {...existingActions, close_one_time_vendor_assistance_modal: true}});
            queryClient.invalidateQueries({queryKey: ["get_user"]});
        }
    }

    const vendor_assistance_whatsapp_url = tablet ? VENDOR_ASSISTANCE_WHATSAPP_MESSAGE : VENDOR_ASSISTANCE_WHATSAPP_MESSAGE_DESKTOP;

    return (
        <SiteModal title="Vendor assistance" className="medium" show={show} onHide={onHideModal}>
            <div className="vendor_assistance">
                <p>
                    Need help finding vendors for your events? Let us help you simplify your planning process with our selection of top-notch vendors,
                    ensuring you get event of your dreams.
                </p>
                <Alert variant="danger" className="whatsapp_message">
                    <h6>Chat with Us on WhatsApp</h6>
                    <p>
                        Immediate support at your fingertips. Instantly discuss your needs with us.{" "}
                        <a
                            target="_blank"
                            href={vendor_assistance_whatsapp_url}
                            // "https://wa.me/message/P75A2BHKTYGFM1"
                        >
                            Message Us
                        </a>
                    </p>
                </Alert>
                {!showPricing ? (
                    <Alert variant="danger" className="whatsapp_message">
                        <h6 className="mb-3">Vendor Assistance Pricing Packages</h6>
                        <div>
                            <h6>❖ One Vendor - ₦7,000</h6>
                            <p>For the essential element that completes your event's vision.</p>
                        </div>
                        <div>
                            <h6>❖ Three Vendors - ₦18,000</h6>
                            <p>Combine Services for a harmonious event ambience.</p>
                        </div>
                        <div>
                            <h6>❖ Five Vendors - ₦29,000</h6>
                            <p>Immerse in the whole Planaday experience with our premium selection.</p>
                        </div>
                        <button className="btn mb-3 mt-md-0 mt-3 border-0 btn-primary" onClick={() => setShowPricing(true)}>
                            Proceed
                        </button>
                    </Alert>
                ) : (
                    <Alert variant="danger" className="form_view">
                        <Form onSubmit={submitHandler}>
                            <Form.Group className="align-items-start vendor_assistance_modal">
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-md-row flex-column mb-md-3">
                                        {vendorAssistanceForm.row1.map((input, index) => {
                                            const inputStyle = index === 0 ? "pr-md-2" : "pl-md-2";
                                            const inputName = input.name as keyof formDetailsType;
                                            return (
                                                <Input
                                                    className={`${inputStyle} my-1 pl-0 pr-0 col-md-6 col-12`}
                                                    key={input.name}
                                                    input={input}
                                                    required
                                                    value={formDetails[inputName]}
                                                    onChange={onFormElementChangeHandler}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className="d-flex flex-md-row flex-column mb-md-3 mb-2">
                                        {vendorAssistanceForm.row2.map((input, index) => {
                                            const inputStyle = index === 0 ? "pr-md-2" : "pl-md-2";
                                            const inputName = input.name as keyof formDetailsType;
                                            return input.type === "input" ? (
                                                <Input
                                                    className={`${inputStyle} my-1 pl-0 pr-0 col-md-6 col-12`}
                                                    key={input.name}
                                                    input={input}
                                                    value={formDetails[inputName]}
                                                    onChange={onFormElementChangeHandler}
                                                />
                                            ) : (
                                                <Select
                                                    className={`${inputStyle} my-1 pl-0 pr-0 col-md-6 col-12`}
                                                    key={input.name}
                                                    options={input.options}
                                                    input={input}
                                                    selectClassName="bg-white"
                                                    value={formDetails[inputName]}
                                                    onChange={onFormElementChangeHandler}
                                                />
                                            );
                                        })}
                                    </div>
                                    {vendorDetails.map((vendorDetail, index) => (
                                        <VendorDetails
                                            key={index}
                                            index={index}
                                            vendorDetailsValue={vendorDetails[index]}
                                            rows={vendorDetails.length}
                                            vendorDetailsItem={vendorDetail}
                                            onAddMoreVendorDetails={onAddMoreVendorDetails}
                                            onUpdateVendorDetails={onUpdateVendorDetails}
                                            onReduceVendorDetails={onReduceVendorDetails}
                                            onUpdateOptionsPicker={onUpdateOptionsPicker}
                                        />
                                    ))}
                                    <div className="d-flex flex-md-row flex-column">
                                        <Textarea
                                            className="pl-0 pr-2 col-md-6 col-12"
                                            value={formDetails.vendorRequirement}
                                            input={vendorAssistanceForm.row3}
                                            onChange={onFormElementChangeHandler}
                                        />
                                        <Textarea
                                            className="pr-0 mt-4 pl-0 mt-md-0 pl-md-2 col-md-6 col-12"
                                            value={formDetails.vendorPlans}
                                            input={vendorAssistanceForm.row4}
                                            onChange={onFormElementChangeHandler}
                                        />
                                    </div>
                                </div>
                                <p className="mt-2">If we are unable to find any vendors for your requested service, we will issue a refund</p>
                                <Button text="Pay" type="submit" className="bg_red mx-auto text-white my-4 font-600 send" isLoading={isLoading} />
                            </Form.Group>
                        </Form>
                    </Alert>
                )}
            </div>
        </SiteModal>
    );
}
