import { PAYSTACK_CURRENCY_CONSTANT } from "./utils/constant";

export default function payStackConfig(email: string, amount: number) {
    const config = {
        reference: new Date().getTime().toString(),
        email,
        amount: amount * PAYSTACK_CURRENCY_CONSTANT, // Amount is in Kobo, so 20000 kobo = N200 , 100 kobo = N1
        publicKey: `${process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}`,
    };

    return config;
}
