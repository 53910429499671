import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import CalculatorForm from "@/components/CalculatorForm";
import { run_model } from "@/axios/post-request";
import { updateOnboardingDetails } from "@/axios/put-request";
import { getUID } from "@/axios/config";
import type { InputData, budgetForm } from "@/data/types";
import CalculatorWrapper from "@/components/CalculatorWrapper";
import { onboardingDetailsStorageType } from "@/types";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import { useQuery } from "@tanstack/react-query";
import { getEvent } from "@/axios/get-request";
import Spinner from "@/components/Spinner";

type formType = {
	form: budgetForm;
	data: InputData[];
};

type eventCeremoniesType = Array<{ label: string; value: string }> | [];

export default function CalculateEventBudget() {
    const containerRef = useRef<HTMLDivElement>(null);
    const [form, setForm] = useState<formType>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const mode = searchParams.get("mode") as "new" | undefined;
    const event_id = searchParams.get("event_id") as string;
    console.log("event_id", event_id);
    const user_id = getUID();
    const { status } = useQuery({
        queryKey: [`user_event_${event_id}`],
        queryFn: () => getEvent(event_id),
        enabled: !!event_id,
        onSuccess: (data) => {
            if (data && data.result) {
                const details = {
                    user: data?.result?.event_owner?.user_type !== "celebrant" ? "Planner" : "Couple",
                    budget: 0,
                    hasBudget: false,
                    days: data?.result?.ceremonies?.length,
                };
                const formData = data?.result.ceremonies.map((item, index) => {
                    return {
                        id: index + 1,
                        location: item.location as string,
                        no_of_guests: Number(item.no_of_guests),
                    };
                });
                setForm((prev) => ({
                    ...prev,
                    form: details,
                    data: formData,
                }));
            }
        },
    });
    const [eventCeremonies, setEventCeremonies] = useState<eventCeremoniesType>([]);
    const calculatorURL = mode === "new" ? "/onboarding/view-event-budget?mode=new" : "/onboarding/view-event-budget";

    console.log("form", form);

    function updateDefaultOnboardingDetails(onboarding_details: onboardingDetailsStorageType) {
        if (onboarding_details) {
            const __ceremonies = onboarding_details?.ceremonies ? onboarding_details?.ceremonies : [];
            const ceremonies = __ceremonies as Array<{ label: string; value: string }> | [];
            setEventCeremonies(ceremonies);
        }
    }

    function prefillCalculatorForm(calculatorDetails: formType | null) {
        if (calculatorDetails) {
            setForm(calculatorDetails);
        }
    }

    useEffect(() => {
        const existingOnboardingDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.onboarding_details);
        const existingCalculatorDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.calculator_details);
        const onboardingDetailsStorage = existingOnboardingDetails ? JSON.parse(existingOnboardingDetails) : null;
        const calculatorDetailsStorage = existingCalculatorDetails ? JSON.parse(existingCalculatorDetails) : null;

        updateDefaultOnboardingDetails(onboardingDetailsStorage);
        prefillCalculatorForm(calculatorDetailsStorage);
    }, []);

    async function calculateBudget(data: InputData[], formData: budgetForm) {
        try {
            setLoading(true);
            const answer = await run_model(data);
            if (answer) {
                setLoading(false);
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.calculator_details, JSON.stringify({ form: formData, data }));
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.model_result, JSON.stringify(answer));
                navigate(calculatorURL);
            }
        } catch (error: unknown) {
            const _error = error as { message: string };
            setLoading(false);
            toast.error(_error.message || "An error occurred, try again later!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    async function onClickCalculatorHandler(form: budgetForm, data: InputData[]) {
        setForm({ form, data });
        calculateBudget(data, form);
        if (data && eventCeremonies.length > 0) {
            await updateOnboardingDetails(user_id, {
                calculator_details: {
                    id: user_id,
                    role: form.user,
                    budget: form.budget,
                    hasBudget: form.hasBudget,
                    days: form.days,
                    data,
                },
                completed: false,
            });
        }
    }

    return (
        <CalculatorWrapper>
            <div className="container calculator_view" ref={containerRef}>
                {event_id && status === "loading" && <Spinner />}
                <CalculatorForm
                    saved={form}
                    onClick={(form: budgetForm, data: InputData[]) => onClickCalculatorHandler(form, data)}
                    isLoading={loading}
                    eventCeremonies={eventCeremonies}
                />
            </div>
        </CalculatorWrapper>
    );
}
