import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useRef, useState, type PropsWithChildren } from "react";

import useMyEvents from "@/hooks/useMyEvents";
import NewDashboardSidebar from "@/components/NewDashboardSidebar";
import ChevronDownIcon from "@/assets/icon/ChevronDownIcon";
import UserRoundIcon from "@/assets/icon/UserRoundIcon";
import CodeIcon from "@/assets/icon/CodeIcon";
import ChevronLeftIcon from "@/assets/icon/ChevronLeftIcon";
import ChevronRightIcon from "@/assets/icon/ChevronRightIcon";
import { getUserDetails } from "@/axios/get-request";
import getFirstChar from "@/utils/getFirstChar";
import useClickOutside from "@/hooks/useClickOutside";
import LogoutIcon from "@/assets/icon/LogoutIcon";
import SettingsCogIcon from "@/assets/icon/SettingsCogIcon";
import useAuth from "@/hooks/useAuth";
import { useEvent } from "@/hooks/useEvent";
import smallLogo from "@/assets/img/planaday_logo_small.png";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import MenuIcon from "@/assets/icon/MenuIcon";
import useFloorPlan from "@/hooks/useFloorPlan";
import "@/styles/dashboard_new.scss";
import { useBudget } from "@/hooks/useBudget";

interface Props {
    className?: string;
    title: string;
    description?: string;
    onUpdateEventHandler?: (eventIndex: number) => void;
}

export default function NewDashboardLayout({ title, description, children, onUpdateEventHandler, className = "" }: PropsWithChildren<Props>) {
    const [activeEventIndex, setActiveIndex] = useState<number | null>(null);
    const { activeEvent, data: eventsData, setSelectedEventId } = useMyEvents();
    const { setActiveCeremonyId, activeCeremonyId, selectedEventId } = useEvent();
    const { setBudget, setBudgetCategoryId } = useBudget();
    const { toggleSidebar } = useFloorPlan();
    const navigate = useNavigate();
    const location = useLocation();
    const mobile = useMediaQuery("(max-width: 768px)");
    const { signUserOut } = useAuth();
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const eventName = activeEvent && activeEvent.length > 0 ? activeEvent[0].event_name : "";
    const { data } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => getUserDetails(),
    });
    const ref = useRef(null);
    const queryClient = useQueryClient();
    useClickOutside(ref, closeDropdown);

    function updateBudgetCategory() {
        if (activeEvent && activeEvent[0].budget && activeEvent[0]?.budget?.categories[0]?.id) {
            setBudget(activeEvent[0].budget);
            setBudgetCategoryId(activeEvent[0]?.budget?.categories[0]?.id);
        }
    }

    useEffect(() => {
        if (activeEventIndex === null && selectedEventId && eventsData) {
            const eventIndex = eventsData.result.findIndex((item) => item.id === selectedEventId);
            setActiveIndex(eventIndex);
        }
        if (eventsData?.result && activeEventIndex !== null) {
            const userEventId = eventsData.result[activeEventIndex]?.id;
            setSelectedEventId(userEventId);
            if (eventsData.result[activeEventIndex]?.ceremonies?.length > 0 && location.pathname !== "/new/dashboard/budget-tracker") {
                setActiveCeremonyId(eventsData.result[activeEventIndex].ceremonies[0].id);
            }
            queryClient.invalidateQueries({ queryKey: [`get_budget_categories_${userEventId}`, `ceremony_${activeCeremonyId}`, "get_all_events"] });
        }
    }, [eventsData, activeEventIndex]);

    function updateEvent() {
        if (activeEventIndex !== null && eventsData && eventsData?.result.length - 1 > activeEventIndex) {
            setActiveIndex((prev) => {
                if (prev) {
                    if (onUpdateEventHandler) {
                        onUpdateEventHandler(prev + 1);
                    }
                    return prev + 1;
                } else {
                    if (onUpdateEventHandler) onUpdateEventHandler(1);
                    return 1;
                }
            });
        } else {
            if (onUpdateEventHandler) onUpdateEventHandler(0);
            setActiveIndex(0);
        }
    }

    function closeDropdown() {
        setShowProfileDropdown(false);
    }

    function goBackHandler() {
        if (location.pathname.includes("dashboard")) {
            return navigate(-1);
        }
    }

    function nextHandler() {
        if (location.pathname.includes("dashboard")) {
            return navigate(+1);
        }
    }

    // const showFloorPlan = data?.result?.user_type && data?.result?.user_type === UserList.planner ? true : false;
    const userName = data?.result?.name ? data?.result?.name : null;
    const businessName = data?.result?.business_name ? data?.result?.business_name : null;
    const name = userName || businessName;

    return (
        <>
            <Helmet>
                <title>{title} - Planaday | Effortless Event Planning & Management</title>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta
                    name="keywords"
                    content="nigerian wedding, asoebi, ankara, lagos wedding, wedding budget, wedding cost, Planaday, plan an event, wedding planning,
                    event planning, event budgeting, event manager, event planner, wedding planner, planning assistant"
                />
                <meta property="og:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    property="og:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta property="og:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta property="og:url" content="https://www.planaday.events" />
                <meta property="og:site_name" content="Planaday Events" />
                <meta property="og:type" content="website" />
                <meta property="og:type" content="Planaday Events" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    name="twitter:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta name="twitter:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:image:alt" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:site" content="@Planaday_events" />
                <meta name="twitter:creator" content="@Planaday_events" />
            </Helmet>
            <ToastContainer autoClose={2000} newestOnTop={false} closeOnClick rtl={false} />
            <div className="dashboard_layout">
                <NewDashboardSidebar showFloorPlan={true} updateBudgetCategory={updateBudgetCategory}>
                    <>
                        {eventName && (
                            <div className="current_event" onClick={updateEvent}>
                                <div className="current_event_group">
                                    <div className="initials">{getFirstChar(eventName)}</div>
                                    <p>{eventName}</p>
                                </div>
                                <button className="update_event">
                                    <CodeIcon />
                                </button>
                            </div>
                        )}
                    </>
                </NewDashboardSidebar>
                <div className={`dashboard_content ${className}`}>
                    <header className="dashboard_header bg-white d-flex">
                        {mobile && (
                            <div className="header_button_group">
                                <button onClick={toggleSidebar}>
                                    <MenuIcon />
                                </button>
                                <Link to="/new/dashboard" className="logo">
                                    <img src={smallLogo} alt="Planaday logo" className="logo-img" />
                                </Link>
                            </div>
                        )}
                        <div className="header_left">
                            <div className="button_group">
                                <button type="button" onClick={goBackHandler}>
                                    <ChevronLeftIcon />
                                </button>
                                <button type="button" onClick={nextHandler}>
                                    <ChevronRightIcon />
                                </button>
                            </div>
                            <div>
                                <h3>{title}</h3>
                                {description && <p>{description}</p>}
                            </div>
                        </div>
                        <div className="header_right">
                            {/* <div className="search_wrapper">
                                <Search fill="#828282" />
                                <input placeholder="Search" />
                            </div> */}
                            <div className="control_group" onClick={() => setShowProfileDropdown((prev) => !prev)}>
                                {/* <button>
                                    <AlarmIcon />
                                </button> */}
                                <span>{name ? getFirstChar(name) : <UserRoundIcon />}</span>
                                <button>
                                    <ChevronDownIcon />
                                </button>
                                {showProfileDropdown && (
                                    <div ref={ref} className="control_group_dropdown">
                                        <Link to="/new/dashboard/settings">
                                            <UserRoundIcon /> View Profile
                                        </Link>
                                        <Link to="/new/dashboard/settings/account">
                                            <SettingsCogIcon /> Account Settings
                                        </Link>
                                        <button className="logout" onClick={() => signUserOut("/new")}>
                                            <span>
                                                <LogoutIcon /> Log out
                                            </span>
                                            <span className="app_version">v2.1</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </header>
                    <main className={`dashboard_main ${className}`}>
                        <div className="d-flex dashboard_layout_wrapper">
                            <div className="dashboard_content">{children}</div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
