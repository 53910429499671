import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CompleteQuestionaire from "@/components/CompleteQuestionaire";
import UploadImage from "@/components/UploadImage";
import AsoebiLayout from "@/layout/AsoebiLayout";
import PreviewQuestionaire from "@/components/PreviewQuestionaire";

export default function CompleteGuestQuestionaire() {
    const [selectedFiles, setSelectedFiles] = useState<File[] | []>([]);
    const [previewQuestionnaire, setPreviewQuestionnaire] = useState(false);
    const navigate = useNavigate();

    function previewQuestionnaireHandler() {
        setPreviewQuestionnaire(true);
        navigate("/asoebi-tracker/complete-questionnaire?mode=preview");
    }

    useEffect(() => {
        navigate("/asoebi-tracker/complete-questionnaire");
    }, []);


    return (
        <AsoebiLayout>
            {previewQuestionnaire ?
                <PreviewQuestionaire selectedFiles={selectedFiles}>
                    <UploadImage showPreviewOnly selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                </PreviewQuestionaire> :
                <CompleteQuestionaire
                    selectedFiles={selectedFiles}
                    onPreviewHandler={previewQuestionnaireHandler}
                >
                    <UploadImage selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                </CompleteQuestionaire>}
        </AsoebiLayout>
    );
}
