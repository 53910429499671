import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import React from "react";

export default function TermOfService() {
    return (
        <NewOnboardingLayout text="Terms of service">
            <div className="content d-flex flex-column">
                <p>
                    <b>Effective Date:</b> 4 September 2024
                </p>
                <p>
                    Welcome to https://planaday.events ("Website"), operated by Planaday Technologies Nigeria Limited ("Planaday", "we", "us", or
                    "our"). By accessing or using our event planning technology platform, you agree to comply with and be bound by the following terms
                    and conditions ("Terms"). Please read them carefully. If you do not agree to these Terms, please do not use our Website.
                </p>
                <div id="general_information">
                    <h5>1. General Information</h5>
                    <ul>
                        <li>
                            <b>Website Name</b>: Planaday
                        </li>
                        <li>
                            <b>Registered Name</b>: Planaday Technologies Nigeria Limited
                        </li>
                        <li>
                            <b>Location</b>: Nigeria
                        </li>
                        <li>
                            <b>Address</b>: Block 7, Plot 23, Fyin Dammy Kasim Street, Magodo Isheri, Lagos, Nigeria
                        </li>
                        <li>
                            <b>Contact Email</b>: support@planaday.events
                        </li>
                    </ul>
                </div>
                <div id="service_description">
                    <h5>2. Service Description</h5>
                    <p>
                        Planaday is an event planning technology platform that provides tools to assist users in organising and managing events. By
                        using our platform, you gain access to various features designed to streamline the event planning process.
                    </p>
                </div>
                <div id="user_responsibilities">
                    <h5>3. User Responsibilities</h5>
                    <h6>3.1 Account Security</h6>
                    Users are responsible for maintaining the confidentiality of their login credentials. You are also responsible for any activities
                    that occur under your account. You must notify us immediately of any unauthorised use of your account or any other breach of
                    security.
                    <h6>3.2 Prohibited Activities</h6>
                    Users are prohibited from engaging in activities that include, but are not limited to: Attempting to hack or disrupt the Website’s
                    functionality. Using the Website for fraudulent purposes, including impersonating another person or entity.
                    <h6>3.3 Content Ownership and Rights</h6>
                    Users should not upload or share content that they do not own or do not have the right to distribute. By submitting content to the
                    Website, you grant Planaday a worldwide, non-exclusive, royalty-free licence to use, reproduce, and distribute your content for
                    the purpose of operating the platform.
                    <h6>3.4 Accuracy of Information</h6>
                    Users are responsible for providing accurate and truthful information when creating accounts, making transactions, or sharing
                    content on the platform. Misleading or false information may result in suspension or termination of your account.
                    <h6>3.5 Use of Services</h6>
                    Users must use the Website’s services only for their intended purpose. You may not use the platform for any unauthorised
                    commercial activities or for purposes that violate any laws or regulations.
                    <h6>3.6 Reporting Violations</h6>
                    Users are encouraged to report any violations of these Terms or suspicious activities to Planaday’s administration via
                    support@planaday.events.
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
