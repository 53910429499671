import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import DashboardModal from "@/components/modals/DashboardModal";
import Spinner from "@/components/Spinner";
import ShieldIcon from "@/assets/icon/ShieldIcon";
import { deleteChecklist } from "@/axios/delete-request";
import { useEvent } from "@/hooks/useEvent";
import useActivityLog from "@/hooks/useActivityLog";

interface Props {
	onClose: () => void;
	selectedChecklistId: string;
	checklistName: string;
	refetchQueryHandler?: () => void;
}

export default function DeleteChecklistModal({ selectedChecklistId, checklistName, refetchQueryHandler, onClose }: Props) {
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const {deleteChecklistLog} = useActivityLog();
    const { activeCeremonyId, selectedEventId } = useEvent();

    async function deleteChecklistHandler() {
        try {
            if (!selectedChecklistId) {
                return toast.error("Checklist id is required");
            }
            setLoading(true);
            const deleteChecklistResponse = await deleteChecklist(activeCeremonyId, selectedChecklistId, selectedEventId);
            if (!deleteChecklistResponse.data.success) {
                setLoading(false);
                return toast.error("Oops unable to delete checklist");
            }
            // toast.success("Checklist deleted");
            if (refetchQueryHandler) {
                refetchQueryHandler();
            }
            await deleteChecklistLog(checklistName);
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}`] });
            return onClose();
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    }

    return (
        <DashboardModal title="Delete Checklist" onClose={onClose} className="small_width delete_modal_view delete_user_event_modal">
            <section className="delete_category_cost_item">
                <div className="icon_wrapper">
                    <ShieldIcon />
                </div>
                <h4>Are you sure you want to delete {checklistName}&#39;s checklist?</h4>

                <button onClick={deleteChecklistHandler}>
					Delete
                    {loading && <Spinner className="ml-2" size={20} />}
                </button>
            </section>
        </DashboardModal>
    );
}
