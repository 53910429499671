import { v4 as uuidv4 } from "uuid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { Suspense, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import LoaderIcon from "@/assets/icon/LoaderIcon";
import TodoIcon from "@/assets/icon/TodoIcon";
import CircleCheckIcon from "@/assets/icon/CircleCheckIcon";
import AddChecklistTaskModal from "@/components/modals/AddChecklistTaskModal";
import { useEvent } from "@/hooks/useEvent";
import { getEvent } from "@/axios/get-request";
import { todoType } from "@/types";
import { updateUserChecklist } from "@/axios/put-request";
import Spinner from "@/components/Spinner";
import CeremonyChecklistItem from "@/components/CeremonyChecklistItem";
import EditChecklistTaskModal from "@/components/modals/EditChecklistTaskModal";
import DeleteChecklistModal from "@/components/modals/DeleteChecklistModal";
import DraggableCeremonyChecklistItem from "@/components/DraggableCeremonyChecklistItem";
import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import DefaultView from "@/components/DefaultView";
import FilterDropdown from "./FilterDropdown";
import "@/styles/checklist_new.scss";

enum ModalList {
	add_checklist_task_modal = "add_checklist_task_modal",
	edit_checklist_task_modal = "edit_checklist_task_modal",
	delete_checklist_task_modal = "delete_checklist_task_modal",
}

type checklistType = Array<{
	isDone: boolean;
	due: Date | null;
	id: string;
	name: string;
	note: string | null;
	priority?: string;
	ceremony: {
		name: string;
		id: string;
	};
}>;

export default function ChecklistView() {
    const [modal, setModal] = useState<string | null>(null);
    const { selectedEventId, activeCeremonyId, setActiveCeremonyId } = useEvent();
    const [loading, setLoading] = useState(false);
    const { data: userData } = useUser();
    const [selectedChecklistId, setSelectedChecklistId] = useState("");
    const [todos, setTodos] = useState<checklistType | []>([]);
    const [selectedCeremonyChecklist, setSelectedCeremonyChecklist] = useState<checklistType | []>([]);
    const queryClient = useQueryClient();
    const { data, status } = useQuery({
        queryKey: [`ceremony_${activeCeremonyId}`],
        queryFn: () => getEvent(selectedEventId),
    });

    useEffect(() => {
        if (status === "success") {
            const ceremonyChecklistsArray: todoType = [];
            if (selectedCeremony[0]?.checklist) {
                selectedCeremony[0]?.checklist.map((item) => {
                    if (item.ceremony.id === activeCeremonyId) {
                        ceremonyChecklistsArray.push(item);
                    }
                });
            }
            const todosArray = ceremonyChecklistsArray.filter((item) => !item.isDone);
            setTodos(todosArray);

            if (
                activeCeremonyId === "all" &&
				data?.result?.ceremonies &&
				Array.isArray(data?.result?.ceremonies) &&
				data?.result?.ceremonies.length > 0
            ) {
                setActiveCeremonyId(data?.result?.ceremonies[0]?.id);
            }
        }
    }, [status, data, selectedEventId]);

    function onCloseModal() {
        setModal(null);
    }

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setTodos((prev) =>
            update(prev, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prev[dragIndex]],
                ],
            }),
        );
    }, []);

    function modalActionHandler(checklistId: string, modalAction: keyof typeof ModalList) {
        const selectedChecklist = ceremonyChecklists.filter((item) => item.id === checklistId);
        setSelectedChecklistId(checklistId);
        setModal(modalAction);
        if (selectedChecklist) {
            setSelectedCeremonyChecklist(selectedChecklist);
        }
    }

    const ceremonies = status === "success" && data?.result?.ceremonies ? data?.result.ceremonies : [];
    const selectedCeremony = activeCeremonyId ? ceremonies.filter((item) => item.id === activeCeremonyId) : [];
    const ceremonyName = selectedCeremony ? selectedCeremony[0]?.name : "";
    const ceremonyChecklists: todoType = [];

    if (selectedCeremony[0]?.checklist) {
        selectedCeremony[0]?.checklist.map((item) => {
            if (item.ceremony.id === activeCeremonyId) {
                ceremonyChecklists.push(item);
            }
        });
    }

    const activeCeremony = data?.result?.ceremonies ? data?.result?.ceremonies.filter((item) => item.id === activeCeremonyId) : [];

    const completedTodos = ceremonyChecklists.filter((item) => item.isDone);

    async function checklistActivityLog(todoItem: todoType[0]) {
        const checklist_action = !todoItem.isDone ? "checklist_completed" : "checklist_not_completed";
        await updateAudit(
            [
                {
                    id: uuidv4(),
                    name: userData?.result?.name as string,
                    user_id: userData?.result?.id as string,
                    email: userData?.result?.email as string,
                    type: "checklist",
                    action: checklist_action,
                    title: todoItem.name,
                    changes: null,
                    timestamp: new Date(),
                },
            ],
            selectedEventId,
        );
    }

    async function updateChecklistStatus(checklist: todoType[0]) {
        try {
            setLoading(true);
            setSelectedChecklistId(checklist.id);
            const updateChecklistRequest = await updateUserChecklist({ ...checklist, isDone: !checklist.isDone }, activeCeremonyId, selectedEventId);
            if (!updateChecklistRequest.data.success) {
                setLoading(false);
                return toast.error(updateChecklistRequest.data.message);
            }
            setSelectedChecklistId("");
            await checklistActivityLog(checklist);
            setLoading(false);
            return queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}`] });
        } catch (error: unknown) {
            const _error = error as { data: { message: string } };
            setLoading(false);
            return toast.error(_error?.data?.message || "Oops an error occured");
        }
    }

    const renderChecklist = useCallback(
        (checklist: checklistType[0], index: number) => {
            return (
                <DraggableCeremonyChecklistItem
                    key={checklist.id}
                    index={index}
                    checklist={checklist}
                    modalActionHandler={(modalAction) => modalActionHandler(checklist.id, modalAction)}
                    moveCard={moveCard}>
                    {loading && checklist.id === selectedChecklistId ? (
                        <Spinner size={20} />
                    ) : (
                        <input checked={checklist.isDone} onChange={() => updateChecklistStatus(checklist)} type="checkbox" />
                    )}
                </DraggableCeremonyChecklistItem>
            );
        },
        [loading, data, updateChecklistStatus],
    );

    function createEventHandler() {
        if (!selectedEventId) {
            return toast.error("Please create an event");
        }
        setModal(ModalList.add_checklist_task_modal);
    }

    return (
        <>
            <Suspense>
                {modal === ModalList.add_checklist_task_modal && <AddChecklistTaskModal ceremonyName={ceremonyName} onClose={onCloseModal} />}
                {modal === ModalList.edit_checklist_task_modal && (
                    <EditChecklistTaskModal checklist={selectedCeremonyChecklist} ceremonyName={ceremonyName} onClose={onCloseModal} />
                )}
                {modal === ModalList.delete_checklist_task_modal && (
                    <DeleteChecklistModal
                        selectedChecklistId={selectedCeremonyChecklist[0]?.id}
                        checklistName={selectedCeremonyChecklist[0]?.name}
                        onClose={onCloseModal}
                    />
                )}
            </Suspense>
            {status === "loading" ? (
                <div className="loading_view mx-auto d-flex justify-content-center align-items-center">
                    <Spinner />
                </div>
            ) : (
                <section className="checklist_page">
                    <div className="checklist_view mt-4">
                        <div className="title_row">
                            <div>
                                <TodoIcon />
                                <h3>To-Do</h3>
                            </div>
                            <div className="button_row">
                                <FilterDropdown title={ceremonyName}>
                                    {ceremonies.map((ceremony) => (
                                        <Dropdown.Item key={ceremony.id} onClick={() => setActiveCeremonyId(ceremony.id)}>
                                            {ceremony.name}
                                        </Dropdown.Item>
                                    ))}
                                </FilterDropdown>
                            </div>
                        </div>
                        {activeCeremony &&
						Array.isArray(activeCeremony) &&
						activeCeremony[0] &&
						activeCeremony[0]?.checklist &&
						activeCeremony[0]?.checklist?.length > 0 ? (
                                <div className="checklist_group">
                                    <div className="todos">
                                        <div className="todo_body">
                                            <div className="todo_status">
                                                <h6>
                                                    <LoaderIcon />
												To-dos <span>{todos.length}</span>
                                                </h6>
                                                <button onClick={() => setModal(ModalList.add_checklist_task_modal)}>Add Item</button>
                                            </div>
                                            {todos.length > 0 ? (
                                                <DndProvider backend={HTML5Backend}>
                                                    {todos.map((checklist, checklistIndex) => renderChecklist(checklist, checklistIndex))}
                                                </DndProvider>
                                            ) : (
                                                <p className="not_available">No available task for this ceremony</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="completed todos">
                                        <div className="todo_body">
                                            <div className="todo_status">
                                                <div className="completed_icon_view">
                                                    <CircleCheckIcon />
                                                    <div className="icon">
                                                        <h6>
														Completed {completedTodos.length}/<span>{ceremonyChecklists.length}</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            {completedTodos.map((checklist) => (
                                                <CeremonyChecklistItem
                                                    key={checklist.id}
                                                    checklist={checklist}
                                                    modalActionHandler={(modalAction) => modalActionHandler(checklist.id, modalAction)}>
                                                    {loading && checklist.id === selectedChecklistId ? (
                                                        <Spinner size={20} />
                                                    ) : (
                                                        <input
                                                            checked={checklist.isDone}
                                                            onChange={() => updateChecklistStatus(checklist)}
                                                            type="checkbox"
                                                        />
                                                    )}
                                                </CeremonyChecklistItem>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <DefaultView
                                    title="You have no Tasks yet"
                                    description="Create tasks and assign deadlines to them"
                                    buttonText="Create Task"
                                    onClickHandler={createEventHandler}
                                />
                            )}
                    </div>
                </section>
            )}
        </>
    );
}
