import { ChangeEvent, useEffect, useState } from "react";
import { format } from "date-fns";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import onboardingFormContent from "@/json/form/onboarding_form.json";
import MinusIcon from "@/assets/icon/MinusIcon";
import { formatNumber } from "@/helper";
import SelectDateInput from "@/components/form/SelectDateInput";
import useCelebrantOnboarding from "@/hooks/useCelebrantOnboarding";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import Loader from "@/components/loader";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import NewCeremonyPicker from "@/components/form/NewCeremonyPicker";
import "@/styles/onboarding_stages.scss";
import "@/styles/auth_page.scss";

type ceremonyPickerType = {
    label: string;
    value: string;
};

const inputGroup = {
    name: { name: "name", label: "Name*", placeholder: "Victoria Skye" },
    eventName: { name: "event_name", label: "Event Name*", placeholder: "Victoria & Thomas Wedding 👩🏾‍🤝‍👨🏽" },
    ceremony: {
        type: { name: "type", label: "Type", placeholder: "White Wedding" },
        location: { name: "location", label: "Location", placeholder: "Seychelles" },
        date: { name: "date", label: "Date", placeholder: format(new Date(), "dd-MM-yyyy") },
        guestNumber: { name: "no_of_guests", label: "Guests", placeholder: "500" },
    },
};

type onboardingFormStateType = {
    name: string;
    event_name: string;
    event_date: null | Date;
    ceremonies: Array<{
        type: string;
        location: string;
        date: Date | string | null;
        no_of_guests: string;
    }>;
    collaborators?: Array<{ role: string; email: string; image: string; name: string }>;
};

type onboardingDetailsType = {
    event_id: string;
    location: string;
    event_name: string;
    event_date: Date;
    no_of_guests: number;
    name: string;
    ceremonies: Array<{
        type: string;
        date: Date | string | null;
        location: string;
        no_of_guests: string;
    }>;
};

export default function OnboardingCelebrantStage1() {
    const isMobileDevice = useMediaQuery("(max-width:560px)");
    const [file, setFile] = useState<File | null>(null);
    const { onboardCelebrantHandler, setUserEventId, loading } = useCelebrantOnboarding(file);
    const [previewEventImage, setPreviewEventImage] = useState<null | string>(null);
    const [ceremonyPickerOptions, setCeremonyPickerOptions] = useState(onboardingFormContent.ceremonyOptions);
    const [onboardingForm, setOnboardingForm] = useState<onboardingFormStateType>({
        name: "",
        event_name: "",
        event_date: null,
        ceremonies: [],
    });

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewEventImage(url);
        setFile(file);
    }

    function updateDefaultOnboardingDetails(onboarding_details: onboardingDetailsType) {
        if (onboarding_details) {
            setOnboardingForm({
                name: onboarding_details?.name ? onboarding_details.name : "",
                event_name: onboarding_details?.event_name ? onboarding_details.event_name : "",
                event_date: onboarding_details?.event_date ? onboarding_details.event_date : null,
                ceremonies: onboarding_details?.ceremonies ? onboarding_details.ceremonies : [],
            });
            setUserEventId(onboarding_details.event_id);
        }
    }

    useEffect(() => {
        const existingOnboardingDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.onboarding_details);
        const onboardingDetailsStorage = existingOnboardingDetails ? JSON.parse(existingOnboardingDetails) : null;
        updateDefaultOnboardingDetails(onboardingDetailsStorage);
    }, []);

    const guestNumberLabel = isMobileDevice ? "Guest no." : inputGroup.ceremony.guestNumber.label;
    const guestNumberInput = { ...inputGroup.ceremony.guestNumber, label: guestNumberLabel };

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        setOnboardingForm((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    // function updateCeremonyTypeHandler(option: string, index: number) {
    //     setOnboardingForm((prev) => {
    //         return {
    //             ...prev,
    //             ceremonies: [...prev.ceremonies.slice(0, index), { ...prev.ceremonies[index], type: option }, ...prev.ceremonies.slice(index + 1)],
    //         };
    //     });
    // }

    function updateSelectedCeremonies(_ceremonyValue: unknown, index: number) {
        const ceremonyValue = _ceremonyValue as ceremonyPickerType & {
            __isNew__: boolean;
        };
        const tempCeremonies = [...onboardingForm.ceremonies];
        const selectedCeremonyIndex = onboardingForm.ceremonies.findIndex((item) => item.type === ceremonyValue.label);
        if (selectedCeremonyIndex !== -1) {
            tempCeremonies[selectedCeremonyIndex] = {
                ...tempCeremonies[selectedCeremonyIndex],
                type: ceremonyValue?.label,
            };
        } else {
            tempCeremonies[index] = {
                ...tempCeremonies[index],
                type: ceremonyValue?.label,
            };
        }
        if (ceremonyValue?.__isNew__) {
            setCeremonyPickerOptions([{ label: ceremonyValue.label, value: ceremonyValue.value }, ...ceremonyPickerOptions]);
        }
        setOnboardingForm({
            ...onboardingForm,
            ceremonies: tempCeremonies,
        });
    }

    function addMoreCeremonyHandler() {
        setOnboardingForm((prev) => {
            return {
                ...prev,
                ceremonies: [
                    ...prev.ceremonies,
                    {
                        type: "",
                        location: "",
                        date: null,
                        no_of_guests: "",
                    },
                ],
            };
        });
    }

    function deleteACeremonyHandler() {
        if (onboardingForm.ceremonies.length >= 1) {
            setOnboardingForm((prev) => {
                const tempCeremony = prev.ceremonies;
                const updatedCeremony = tempCeremony.slice(0, tempCeremony.length - 1);
                return {
                    ...prev,
                    ceremonies: updatedCeremony,
                };
            });
        }
    }

    function updateCeremonyHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const updateCeremony = onboardingForm.ceremonies.map((formDetails, idx: number) => {
            if (event.target.name === "no_of_guests" && index === idx) {
                const guest_no_limit = formatNumber(event.target.value);

                return {
                    ...formDetails,
                    no_of_guests: event.target.value ? guest_no_limit : "",
                };
            }
            if (index === idx) {
                return {
                    ...formDetails,
                    [event.target.name]: event.target.value,
                };
            } else return formDetails;
        });
        setOnboardingForm((prev) => {
            return {
                ...prev,
                ceremonies: updateCeremony,
            };
        });
    }

    function updateCeremonyDateHandler(date: Date, index: number) {
        const updateCeremony = onboardingForm.ceremonies.map((formDetails, idx: number) => {
            if (index === idx) {
                return {
                    ...formDetails,
                    date: new Date(date).toISOString(),
                };
            } else return formDetails;
        });
        setOnboardingForm((prev) => {
            return {
                ...prev,
                ceremonies: updateCeremony,
            };
        });
    }

    const ceremonyFormClassName = onboardingForm.ceremonies.length === 1 ? "form_first_stage" : "";

    function selectEventDateHandler(date: Date | null) {
        setOnboardingForm((prev) => {
            return {
                ...prev,
                event_date: date,
            };
        });
    }
    const today = new Date();

    const className = loading ? "loading" : "";

    return (
        <NewOnboardingLayout className="onboarding_page_stage" text="Unlock your dream event" text2="in a few steps...">
            <div className="content d-flex flex-column auth">
                <div className="auth_content onboarding_stage">
                    <ul className="onboarding_stepper">
                        <li className="active">
                            <p>Step 1</p>
                            <div>
                                <span className="active"></span>
                            </div>
                        </li>
                        <li>
                            <p>Step 2</p>
                            <div>
                                <span></span>
                            </div>
                        </li>
                        <li>
                            <p>Step 3</p>
                            <div>
                                <span></span>
                            </div>
                        </li>
                        <div className="hr_wrapper">
                            <hr />
                        </div>
                    </ul>
                    <h4 className="mb-0">Your details please 📄</h4>
                    <form className={ceremonyFormClassName}>
                        <div className="input_list_group mb-4">
                            <div className="input_list">
                                <div className="business_name">
                                    <Input2 input={inputGroup.name} onChange={updateInputHandler} type="text" value={onboardingForm.name} />
                                    <div className="upload_event_banner">
                                        <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />{" "}
                                        {previewEventImage ? <img src={previewEventImage} alt="event image" /> : <GalleryIcon />}
                                    </div>
                                </div>
                                <Input2 input={inputGroup.eventName} onChange={updateInputHandler} value={onboardingForm.event_name} type="text" />
                                <SelectDateInput
                                    onClick={selectEventDateHandler}
                                    value={onboardingForm.event_date}
                                    label="Event Date"
                                    placeholder="Select Event Date"
                                    containerClassName="input2 select_date_input"
                                    minDate={today}
                                />
                            </div>
                        </div>
                        <div className="ceremony_section form_row">
                            <div className="section_title">
                                <div>
                                    <CalendarIcon />
                                    <h6>Ceremonies</h6>
                                </div>
                                <div className="button_action_group">
                                    <button type="button" onClick={addMoreCeremonyHandler}>
                                        Add <PlusIcon fill="#000" />
                                    </button>
                                    {onboardingForm.ceremonies.length >= 1 && (
                                        <button type="button" onClick={deleteACeremonyHandler}>
                                            Remove <MinusIcon />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="ceremonies">
                                {onboardingForm.ceremonies.map((ceremonyDetails, index) => {
                                    return (
                                        <div key={index}>
                                            <NewCeremonyPicker
                                                value={{ label: onboardingForm.ceremonies[index].type }}
                                                defaultValue={{ label: ceremonyDetails.type, value: ceremonyDetails.type }}
                                                onChange={(ceremony) => updateSelectedCeremonies(ceremony, index)}
                                                options={ceremonyPickerOptions}
                                            />
                                            <div className="input_group">
                                                <Input2
                                                    className="location_input"
                                                    input={inputGroup.ceremony.location}
                                                    value={ceremonyDetails.location}
                                                    onChange={(event) => updateCeremonyHandler(event, index)}
                                                    type="text"
                                                />
                                                <SelectDateInput
                                                    className="ceremony_date"
                                                    label={inputGroup.ceremony.date.label}
                                                    placeholder={inputGroup.ceremony.date.placeholder}
                                                    value={ceremonyDetails.date as Date}
                                                    minDate={new Date()}
                                                    containerClassName="input2"
                                                    onClick={(date) => updateCeremonyDateHandler(date, index)}
                                                />
                                                <Input2
                                                    className="select_input"
                                                    input={guestNumberInput}
                                                    value={ceremonyDetails.no_of_guests}
                                                    onChange={(event) => updateCeremonyHandler(event, index)}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="button_group mx-auto justify-content-center">
                    <button
                        type="button"
                        className={`next_btn ${className}`}
                        onClick={() => onboardCelebrantHandler(onboardingForm)}
                        disabled={loading}>
                        Next {loading && <Loader />}
                    </button>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
