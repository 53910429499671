import Button from "@/components/Button";
import AddBudgetList from "@/components/AddBudgetList";
import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import arrowup from "@/assets/img/arrow-chevron_up.svg";
import { useFilter } from "@/hooks/useFilter";
import { useBudgetDropdown } from "@/hooks/useBudgetDropdown";
import type { categoryType } from "@/types";
import trash from "@/assets/img/trash.svg";

interface Props {
    category: categoryType;
    index: number;
}

export default function BudgetList({ category, index }: Props) {
    const { budgetDropdown, setBudgetDropdown } = useBudgetDropdown();
    const { ceremony } = useFilter();

    function toggleDropdown() {
        const dropdownValue = budgetDropdown.categoryId === category.id ? null : category.id;
        setBudgetDropdown({
            ...budgetDropdown,
            categoryId: dropdownValue,
        });
    }

    const selectedCostItems = ceremony?.id !== "all" ? category.cost_items.filter((item) => item.tag === ceremony?.id) : category.cost_items;

    const categoryCount = selectedCostItems.length;
    const budgetItem = categoryCount > 1 ? "items" : "item";

    const totalActualCost = selectedCostItems.reduce((a, b) => a + b.actual, 0);
    const totalEstimate = selectedCostItems.reduce((a, b) => a + b.estimate, 0);

    const dropdownIcon = budgetDropdown.categoryId === category.id ? arrowup : arrowdown;
    const categoryEstimate = totalEstimate.toLocaleString("en-US");
    const categoryActual = totalActualCost.toLocaleString("en-US");

    return (
        <div className="budget_list">
            <div className="d-flex justify-content-between flex-row align-items-start align-items-lg-center">
                <h4>{category.name}</h4>
                <div className="d-flex align-items-center">
                    <p className="mb-0">
                        {categoryCount} {budgetItem}
                    </p>
                    <button className="btn">
                        <img src={trash} />
                    </button>
                </div>
            </div>
            <div className="budget_rate mt-1 d-flex justify-content-between align-items-start align-items-lg-center">
                <div className="prices  d-flex flex-lg-row flex-column align-items-start align-items-lg-center">
                    <h6 className="mb-0">
                        <span className="mr-1">ESTIMATE:</span>₦{categoryEstimate}
                    </h6>
                    <h6 className="mb-0">
                        <span className="mr-1">ACTUAL:</span>₦{categoryActual}
                    </h6>
                </div>
                <Button id={`toggle_budget_breakdown_category_${index}`} icon={dropdownIcon} onClick={toggleDropdown} className="p-0" />
            </div>
            {budgetDropdown.categoryId === category.id && <AddBudgetList category={category} />}
        </div>
    );
}
