import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import ForgotPasswordModal from "@/components/modals/ForgotPasswordModal";
import Input from "@/components/form/Input";
import authform from "@/json/form/auth_form.json";
import Button from "@/components/Button";
import PasswordInput from "@/components/form/PasswordInput";
import useAuth from "@/hooks/useAuth";
import AuthLayout from "@/layout/AuthLayout";
import { validateEmail } from "@/helper";

export default function Login() {
    const { loading, signInWithUserEmailAndPassword } = useAuth();
    const [searchParams] = useSearchParams();
    const invitee_role = searchParams.get("role");
    const inviter_email = searchParams.get("inviter_email");
    const inviter_id = searchParams.get("id");
    const event_id = searchParams.get("event_id");
    const email = searchParams.get("email");

    const [modal, setModal] = useState(false);
    const [userDetails, setUserDetails] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        if (email) {
            setUserDetails({
                ...userDetails,
                email,
            });
        }
    }, [email]);

    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        const { email, password } = userDetails;
        if (!email) {
            return toast.error("Email address is required.");
        }
        if (!validateEmail(email)) {
            return toast.error("Please enter a valid email address.");
        }
        if (!password) {
            return toast.error("Password is required.");
        }
        if (password.length < 6) {
            return toast.error("Password must be at least 6 characters long.");
        }
        return await signInWithUserEmailAndPassword(email, password, {
            inviter_id,
            invitee_role,
            inviter_email,
            event_id,
        });
    }

    function closeModal() {
        setModal(false);
    }

    function openModal() {
        setModal(true);
    }

    return (
        <>
            <ForgotPasswordModal show={modal} onHide={closeModal} />
            <AuthLayout type="login">
                <form className="login_form" onSubmit={onSubmitHandler}>
                    <Input input={authform.email} value={userDetails.email} onChange={onChangeHandler} />
                    <PasswordInput input={authform.password} value={userDetails.password} className="mb-0" onChange={onChangeHandler} />
                    <button className="mb-2 forgot_password" onClick={openModal} type="button">
						Forgot your password ?
                    </button>
                    <Button
                        testId="login_button"
                        text="Log in"
                        type="submit"
                        className="bg_red text-white px-4 font-weight-bold mx-auto d-flex align-items-center justify-content-center"
                        isLoading={loading}
                    />
                </form>
            </AuthLayout>
        </>
    );
}
