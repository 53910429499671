import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import type { ChangeEvent } from "react";

import Input from "@/components/form/Input";
import authform from "@/json/form/auth_form.json";
import Button from "@/components/Button";
import PasswordInput from "@/components/form/PasswordInput";
import useAuth from "@/hooks/useAuth";
import AuthLayout from "@/layout/AuthLayout";
import { validateEmail } from "@/helper";

export default function Signup() {
    const [searchParams] = useSearchParams();
    const inviter_email = searchParams.get("inviter_email");
    const inviter_id = searchParams.get("id");
    const invitee_role = searchParams.get("role");
    const event_id = searchParams.get("event_id");
    const email = searchParams.get("email");
    const { loading, createNewUserWithEmailAndPassword } = useAuth();

    const [userDetails, setUserDetails] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        if (email) {
            setUserDetails({
                ...userDetails,
                email,
            });
        }
    }, [email]);

    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        const { email, password } = userDetails;
        if (!email) {
            return toast.error("Email address is required.");
        }
        if (!validateEmail(email)) {
            return toast.error("Email address is required.");
        }
        if (!password) {
            return toast.error("Password is required.");
        }
        if (password.length < 6) {
            return toast.error("Password must be at least 6 characters long.");
        }
        await createNewUserWithEmailAndPassword(email, password, {
            inviter_id,
            invitee_role,
            inviter_email,
            event_id,
        });
    }

    const buttonStyle = loading ? "px-5" : "px-4";
    const disableButton = userDetails.email && userDetails.password && !loading ? false : true;

    return (
        <AuthLayout type="signup">
            <form onSubmit={onSubmitHandler}>
                <Input input={authform.email} value={userDetails.email} onChange={onChangeHandler} />
                <PasswordInput input={authform.password} value={userDetails.password} onChange={onChangeHandler} />
                <Button
                    type="submit"
                    text="Sign up"
                    className={`${buttonStyle} bg_red submit text-white font-weight-bold mx-auto d-flex align-items-center justify-content-center`}
                    isLoading={loading}
                    disabled={disableButton}
                />
            </form>
        </AuthLayout>
    );
}
