import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { Dropdown } from "react-bootstrap";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import SelectDateInput from "@/components/form/SelectDateInput";
import SiteDropdown from "@/components/Dropdown";
import { DisplayChecklistStatus } from "../CeremonyChecklistItem";
import { updateUserChecklist } from "@/axios/put-request";
import "@/styles/add_budget_category.scss";
import useActivityLog from "@/hooks/useActivityLog";

const formDetails = {
    task: { label: "Task", name: "name", placeholder: "Task name" },
    datepicker: { label: "Date", placeholder: format(new Date(), "dd-MM-yy"), name: "due" },
    priority: { label: "Priority", placeholder: "Select priority", name: "priority" },
};

interface Props {
    onClose: () => void;
    ceremonyName: string;
    checklist: checklistType;
    refetchQueryHandler?: () => void;
}

type checklistType = Array<{
    isDone: boolean;
    due: Date | null;
    id: string;
    name: string;
    note: string | null;
    priority?: string;
    ceremony: {
        name: string;
        id: string;
    };
}>;

const priorityOption = ["Low", "Medium", "High", "Urgent"];

export default function EditChecklistTaskModal({ onClose, checklist, refetchQueryHandler }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const queryClient = useQueryClient();
    const { editChecklistActivityLog } = useActivityLog();
    const [selectedChecklist, setSelectedChecklist] = useState(checklist[0]);
    const [loading, setLoading] = useState(false);

    async function updateCeremonyTask() {
        try {
            if (!selectedChecklist.name) {
                return toast.error("Task name is required");
            }

            setLoading(true);
            const updateUserChecklistResponse = await updateUserChecklist(selectedChecklist, activeCeremonyId, selectedEventId);
            if (!updateUserChecklistResponse.data.success) {
                setLoading(false);
                return toast.error(updateUserChecklistResponse?.data?.message);
            }
            setLoading(false);
            toast.success(updateUserChecklistResponse?.data?.message);
            await editChecklistActivityLog(selectedChecklist, checklist[0]);
            if (refetchQueryHandler) {
                refetchQueryHandler();
            }
            queryClient.resetQueries({ queryKey: [`ceremony_${activeCeremonyId}`] });
            return onClose();
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Oops, unable to update checklist");
            onClose();
        }
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        setSelectedChecklist((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    function onSelectHandler(value: Date | string, name: string) {
        setSelectedChecklist((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    }

    return (
        <DashboardModal
            title="Edit task"
            description="Edit ceremony task"
            className="small_width add_budget_category_modal add_checklist_modal"
            onClose={onClose}>
            <div className="add_checklist_modal">
                <div className="checklist_group">
                    <div className="checklist_row">
                        <Input2
                            value={selectedChecklist?.name}
                            input={formDetails?.task}
                            onChange={updateInputHandler}
                            containerClassName="form_input_element"
                        />
                        <SelectDateInput
                            value={selectedChecklist?.due}
                            label={formDetails.datepicker.label}
                            containerClassName="form_input_element"
                            placeholder={formDetails.datepicker.placeholder}
                            onClick={(date) => onSelectHandler(date, "due")}
                        />
                        <div className="dropdown_wrapper form_input_element">
                            <label htmlFor="select_priority">Priority</label>
                            <SiteDropdown
                                id="select_priority"
                                title={
                                    <DisplayChecklistStatus title={selectedChecklist?.priority ? selectedChecklist.priority : "Select priority"} />
                                }
                                className="custom">
                                {priorityOption.map((item) => (
                                    <Dropdown.Item key={item} value={item} onClick={() => onSelectHandler(item, "priority")}>
                                        {item}
                                    </Dropdown.Item>
                                ))}
                            </SiteDropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal_footer mt-5">
                <div className="button_group">
                    <button type="button" onClick={onClose} className="cancel_btn">
						Cancel
                    </button>
                    <button type="button" className="btn create_btn d-flex align-items-center" onClick={updateCeremonyTask}>
						Update {loading && <Spinner className="ml-2" size={20} />}
                    </button>
                </div>
            </div>
        </DashboardModal>
    );
}
