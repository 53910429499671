import ArmchairIcon from "@/assets/icon/ArmchairIcon";
import EntertainmentIcon from "@/assets/icon/EntertainmentIcon";
import StageIcon from "@/assets/icon/StageIcon";
import GiftIcon from "@/assets/icon/GiftIcon";
import ShapesIcon from "@/assets/icon/ShapesIcon";
import UtensilsIcon from "@/assets/icon/Utensils";
import SofaIcon from "@/assets/icon/SofaIcon";
import TableOfTenIcon from "@/assets/icon/TableOfTenIcon";
import FloorplanCategoryTextIcon from "@/components/FloorplanElements/FloorplanCategoryTextIcon";
import DoorIcon from "@/assets/icon/DoorIcon";
import LayeredStageIcon from "@/assets/icon/LayeredStageIcon";
import GiftsIcon from "@/assets/icon/GiftsIcon";
import DoubleOpenDoorIcon from "@/assets/icon/DoubleOpenDoorIcon";
import DoubleClosedDoorIcon from "@/assets/icon/DoubleClosedDoorIcon";
import SingleOpenDoorIcon from "@/assets/icon/SingleOpenDoorIcon";
import SingleCloseDoorIcon from "@/assets/icon/SingleCloseDoorIcon";
import CircleIcon from "@/assets/icon/CircleIcon";
import SquareIcon from "@/assets/icon/SquareIcon";
import RectangleIcon from "@/assets/icon/RectangleIcon";
import TripleSofaIcon from "@/assets/icon/TripleSofaIcon";
import DoubleSofaIcon from "@/assets/icon/DoubleSofaIcon";
import Rectangle3Icon from "@/assets/icon/Rectangle3Icon";
import Rectangle6Icon from "@/assets/icon/Rectangle6Icon";
import Rectangle8Icon from "@/assets/icon/Rectangle8Icon";
import Rectangle10Icon from "@/assets/icon/Rectangle10Icon";
import TableOfEightIcon from "@/assets/icon/TableOfEightIcon";
import { FloorPlanObjectList } from "@/data/types";
import "@/styles/draw_floor_plan.scss";

export const fillSpaceElements = [
    {
        text: "Tables & Chairs",
        type: FloorPlanObjectList.table_chairs__category,
        icon: <ArmchairIcon />,
    },
    {
        text: "Entertainment",
        type: FloorPlanObjectList.entertainment__category,
        icon: <EntertainmentIcon />,
    },
    {
        text: "Food & Drinks",
        type: FloorPlanObjectList.food_drinks__category,
        icon: <UtensilsIcon />,
    },
    {
        text: "Stage",
        type: FloorPlanObjectList.stage__category,
        icon: <StageIcon />,
    },
    {
        text: "Doors",
        type: FloorPlanObjectList.doors__category,
        icon: <DoorIcon />,
    },
    {
        text: "Gifts",
        type: FloorPlanObjectList.gifts__category,
        icon: <GiftIcon />,
    },
    {
        text: "Shapes",
        type: FloorPlanObjectList.shapes__category,
        icon: <ShapesIcon />,
    },
];

export const fillSpaceObjectElements = {
    table_chairs__category: [
        {
            type: FloorPlanObjectList.sofa__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<TripleSofaIcon />} text="Sofa" />,
        },
        {
            type: FloorPlanObjectList.single_chair__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SofaIcon />} text="Single" />,
        },
        {
            type: FloorPlanObjectList.double_chair__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<DoubleSofaIcon />} text="Double" />,
        },
        {
            type: FloorPlanObjectList.square__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SquareIcon />} text="Square" />,
        },
        {
            type: FloorPlanObjectList.round_table__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<CircleIcon />} text="Round" />,
        },
        {
            type: FloorPlanObjectList.table_of_10__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<TableOfTenIcon />} text="Round" description="+10" />,
        },
        {
            type: FloorPlanObjectList.table_of_8__table_chairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<TableOfEightIcon />} text="Round" description="+8" />,
        },
        {
            type: FloorPlanObjectList.rectangle__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<RectangleIcon />} text="Rectangle" />,
        },
        {
            type: FloorPlanObjectList.rectangle_3__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle3Icon />} text="Rectangle" description="+3" />,
        },
        {
            type: FloorPlanObjectList.rectangle_6__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle6Icon />} text="Rectangle" description="+6" />,
        },
        {
            type: FloorPlanObjectList.rectangle_8__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle8Icon />} text="Rectangle" description="+8" />,
        },
        {
            type: FloorPlanObjectList.rectangle_10__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<Rectangle10Icon />} text="Rectangle" description="+10" />,
        },
    ],
    entertainment__category: [
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Band",
            icon: <FloorplanCategoryTextIcon title="Band" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "DJ",
            icon: <FloorplanCategoryTextIcon title="DJ" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Sound",
            icon: <FloorplanCategoryTextIcon title="Sound" description="Equipment" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Dance Floor",
            icon: <FloorplanCategoryTextIcon title="Dance Floor" />,
        },
    ],
    food_drinks__category: [
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Buffet",
            icon: <FloorplanCategoryTextIcon title="Buffet" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Cake Table",
            icon: <FloorplanCategoryTextIcon title="Cake" text="Cake Table" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Cocktails",
            icon: <FloorplanCategoryTextIcon title="Cocktails" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Drinks Bar",
            icon: <FloorplanCategoryTextIcon title="Drinks" text="Drinks Bar" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Dessert Table",
            icon: <FloorplanCategoryTextIcon title="Dessert" description="Table" />,
        },
        {
            type: FloorPlanObjectList.element_with_text,
            title: "Catering Station",
            icon: <FloorplanCategoryTextIcon title="Catering" description="Station" />,
        },
    ],
    stage__category: [
        {
            type: FloorPlanObjectList.stage_stairs__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<LayeredStageIcon />} text="Stage" description="+stairs" />,
        },
        {
            type: FloorPlanObjectList.stage__floor_plan_element,
            icon: <FloorplanCategoryTextIcon title="Stage" description="-stairs" />,
        },
    ],
    doors__category: [
        {
            type: FloorPlanObjectList.double_door_open__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<DoubleOpenDoorIcon />} text="Double" description="Open" />,
        },
        {
            type: FloorPlanObjectList.double_door_closed__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<DoubleClosedDoorIcon />} text="Double" description="Closed" />,
        },
        {
            type: FloorPlanObjectList.single_door_open__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SingleOpenDoorIcon />} text="Single" description="Open" />,
        },
        {
            text: "Food & Drinks",
            type: FloorPlanObjectList.single_door_closed__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SingleCloseDoorIcon />} text="Single" description="Closed" />,
        },
    ],
    gifts__category: [
        {
            text: "Gift",
            type: FloorPlanObjectList.gifts__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<GiftsIcon />} text="Gifts" />,
        },
    ],
    shapes__category: [
        {
            text: "Shapes",
            type: FloorPlanObjectList.circle__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<CircleIcon />} text="Circle" />,
        },
        {
            text: "Shapes",
            type: FloorPlanObjectList.square__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<SquareIcon />} text="Square" />,
        },
        {
            text: "Shapes",
            type: FloorPlanObjectList.rectangle__floor_plan_element,
            icon: <FloorplanCategoryTextIcon icon={<RectangleIcon />} text="Rectangle" />,
        },
    ],
};

export const entertainmentElements = [
    {
        text: "Entertainment",
        type: FloorPlanObjectList.entertainment__category,
        icon: <EntertainmentIcon />,
    },
];

export const foodDrinksElements = [
    {
        text: "Food & Drinks",
        type: FloorPlanObjectList.food_drinks__category,
        icon: <UtensilsIcon />,
    },
];
