import { Dropdown } from "react-bootstrap";
import { lazy, Suspense, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import BudgetCategoryCard from "@/components/BudgetCategoryCard";
import DisplayBudgetCategoryIcon from "@/components/DisplayBudgetCategoryIcon";
import SiteDropdown from "@/components/Dropdown";
import PlusIcon from "@/assets/icon/PlusIcon";
import ChevronDownIcon from "@/assets/icon/ChevronDownIcon";
import { addCostItemToBudgetCategoryRequest } from "@/axios/post-request";
import Spinner from "@/components/Spinner";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import BudgetCategoryCardItem from "./BudgetCategoryCardItem";
import { useEvent } from "@/hooks/useEvent";
import { useBudget } from "@/hooks/useBudget";
import { deleteBudgetCategory } from "@/axios/delete-request";
import useAudit from "@/hooks/useAudit";
import type { budgetType, categoryType, ceremonyType } from "@/types";

const DeleteBudgetCategoryModal = lazy(() => import("@/components/modals/DeleteBudgetCategoryModal"));
const RenameBudgetCategoryModal = lazy(() => import("@/components/modals/RenameBudgetCategoryModal"));
const DeleteBudgetCategoryCostItemModal = lazy(() => import("./modals/DeleteBudgetCategoryCostItemModal"));

interface Props {
    addBudgetCategoryHandler: () => void;
    ceremonies: ceremonyType | null;
    categories: categoryType[] | null;
}

enum ModalList {
    delete_category_costitem_modal = "delete_category_costitem_modal",
    rename_budget_category_modal = "rename_budget_category_modal",
    delete_budget_category_modal = "delete_budget_category_modal",
}

type ModalType = keyof typeof ModalList;

export default function EventBudgetBreakdown({ addBudgetCategoryHandler, categories, ceremonies }: Props) {
    const [modal, setModal] = useState<ModalType | null>(null);
    const [loading, setLoading] = useState(false);
    const { selectedEventId, activeCeremonyId } = useEvent();
    const { updateAuditHandler } = useAudit();
    const queryClient = useQueryClient();
    const [activeCostItemIndex, setActiveCostItemIndex] = useState<number | null>(null);
    const [costItemName, setCostItemName] = useState("");
    const {
        budget,
        setBudget,
        setBudgetCategoryId,
        budgetCategoryId,
        costItemId,
        deleteBudgetCategory: deleteEventBudgetCategory,
        setCostItemId,
    } = useBudget();

    const budgetCategories = budget?.categories ? budget?.categories : categories;
    const category = budgetCategories?.filter((item) => item.id === budgetCategoryId)[0];

    async function updateCategory() {
        const oddCategoryId = budget?.categories?.filter((item) => item.name === "Undefined")[0]?.id;
        if (oddCategoryId) {
            await deleteBudgetCategory(oddCategoryId, selectedEventId);
            deleteEventBudgetCategory(oddCategoryId);
        }
    }

    function showCostItemDropdown(index: number) {
        setActiveCostItemIndex((prev) => {
            if (prev === index) {
                return null;
            } else {
                return index;
            }
        });
    }

    useEffect(() => {
        updateCategory();
    }, []);

    async function addCostItemHandler() {
        try {
            if (!budgetCategoryId) {
                return toast.error("Please select a budget category");
            }
            setLoading(true);
            const response = await addCostItemToBudgetCategoryRequest(selectedEventId, budgetCategoryId, activeCeremonyId);
            setLoading(false);

            if (!response.data.success) {
                return toast.error(response.data.message);
            }
            const budgetDetails = {
                ...budget,
                categories: response.data.result as Array<categoryType>,
            } as budgetType;

            if (budget) {
                setBudget(budgetDetails);
                setBudgetCategoryId(budgetCategoryId);
            }
            queryClient.invalidateQueries({ queryKey: [`get_budget_categories_${selectedEventId}_${activeCeremonyId}`] });
            if (category?.name) {
                updateAuditHandler("budget_tracker", "add_budget_cost_item_category", category?.name as string);
            }
        } catch (error) {
            return toast.error("Oops an error occured");
        }
    }

    function onToggleCostItem(activeCostItemId: string, index: number) {
        showCostItemDropdown(index);
        if (costItemId !== activeCostItemId) {
            setCostItemId(activeCostItemId);
        } else {
            setCostItemId("");
        }
    }

    function onClickBudgetCategoryHandler(category: categoryType) {
        setBudgetCategoryId(category.id);
    }

    function onCloseModal() {
        setModal(null);
    }

    function showDeletebudgetCategoryModal() {
        setModal(ModalList.delete_budget_category_modal);
    }

    function showRenameBudgetCategoryTitleModal() {
        setModal(ModalList.rename_budget_category_modal);
    }

    function showDeleteCostItemModal(activeCostItemId: string, costItemName: string) {
        setCostItemId(activeCostItemId);
        setCostItemName(costItemName);
        setModal(ModalList.delete_category_costitem_modal);
    }

    const categoryDetails = category ? { name: category.name, id: category.id } : null;

    const budgetCostItem =
        category && activeCeremonyId === "all" ? category?.cost_items : category?.cost_items.filter((item) => item.tag === activeCeremonyId);

    return (
        <>
            <Suspense>
                {ModalList.delete_budget_category_modal === modal && categoryDetails && (
                    <DeleteBudgetCategoryModal categoryDetails={categoryDetails} onClose={onCloseModal} />
                )}
                {ModalList.rename_budget_category_modal === modal && categoryDetails && (
                    <RenameBudgetCategoryModal categoryDetails={categoryDetails} onClose={onCloseModal} />
                )}
                {modal === ModalList.delete_category_costitem_modal && category && (
                    <DeleteBudgetCategoryCostItemModal costItemName={costItemName} categoryId={category.id} onClose={onCloseModal} />
                )}
            </Suspense>
            <section className={`budget_breakdown list`}>
                <div className="budget_breakdown_title">
                    <h4>Budget Breakdown</h4>
                    <div className="budget_breakdown_btns">
                        <button type="button" className="add_category" onClick={addBudgetCategoryHandler}>
                            <span> Add Category </span> <PlusIcon fill="#000" />
                        </button>
                        <SiteDropdown className="custom action_dropdown three_dot" title={<ThreedotIcon />}>
                            <Dropdown.Item onClick={showRenameBudgetCategoryTitleModal}>Rename Category</Dropdown.Item>
                            <Dropdown.Item onClick={showDeletebudgetCategoryModal}>Delete Category</Dropdown.Item>
                        </SiteDropdown>
                    </div>
                </div>
                <div className="budget_breakdown_categories">
                    <div className="category_names">
                        {budget &&
                            budget?.categories &&
                            Array.isArray(budget?.categories) &&
                            budget.categories.length > 0 &&
                            budget?.categories.map((category, index) => {
                                const title =
                                    category.name === "Food & drink"
                                        ? "Food & drinks"
                                        : category.name === "Wedding & apparel"
                                            ? "Wedding apparel"
                                            : category.name;

                                const activeCategory = budgetCategoryId === category.id ? "active" : "";

                                return (
                                    <div
                                        key={index}
                                        className={`budget_category_card ${activeCategory}`}
                                        onClick={() => onClickBudgetCategoryHandler(category)}>
                                        <div className="icon_row">
                                            <DisplayBudgetCategoryIcon iconName={category.name} />
                                            <button>
                                                <ChevronDownIcon />
                                            </button>
                                        </div>
                                        <h6>{title}</h6>
                                    </div>
                                );
                            })}
                    </div>
                    {category && (
                        <BudgetCategoryCard category={category}>
                            {Array.isArray(budgetCostItem) &&
                                budgetCostItem.map((costItem, index) => {
                                    return (
                                        <BudgetCategoryCardItem
                                            key={costItem.id}
                                            activeCategoryId={budgetCategoryId}
                                            costItem={costItem}
                                            index={index}
                                            activeCostItemIndex={activeCostItemIndex}
                                            ceremonies={ceremonies}
                                            onToggleCostItem={(costItemId) => onToggleCostItem(costItemId, index)}
                                            showDeleteCostItemModal={showDeleteCostItemModal}
                                        />
                                    );
                                })}
                            <tr className="add_cost_row">
                                <td colSpan={6}>
                                    <button className="add_cost" onClick={addCostItemHandler}>
                                        Add Cost <PlusIcon fill="#000" />
                                        {loading && (
                                            <span className="mx-0">
                                                <Spinner size={14} />
                                            </span>
                                        )}
                                    </button>
                                </td>
                            </tr>
                        </BudgetCategoryCard>
                    )}
                </div>
            </section>
        </>
    );
}
